<template>
  <defaultLayout>
    <div class="geofence">
      <deviceDrawer :clickVehicle="clickVehicle" v-show="true" />

      <div class="google-map">
        <div class="geofence-creation-btn">
          <a-dropdown>
            <a-button
              size="large"
              style="width: 100%; display: inline-block; font-size: 100%"
              >สร้างสถานี
            </a-button>
            <template #overlay>
              <a-menu @click="drawingModeManager">
                <a-menu-item key="circleDrawing"> วงกลม </a-menu-item>
                <a-menu-item key="polygonDrawing"> โพลีกอน </a-menu-item>
                <a-menu-item key="circleCustom">
                  กรอกรายละเอียดด้วยตัวเอง
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
        <GmapMap
          :center="{ lat: 13.7460067, lng: 100.5440535 }"
          :zoom="this.mapZoom"
          gestureHandling="greedy"
          map-type-id="roadmap"
          style="width: 100%; height: calc(100vh)"
          ref="googleMap"
          :options="{
            zoomControl: true,
            mapTypeControl: true,
            scaleControl: false,
            streetViewControl: true,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUi: true,
            zoomControlOptions: {
              position: 3,
            },
            streetViewControlOptions: {
              position: 3,
            },
            clickableIcons: true,
          }"
          @click="clickPosition"
          @drag="startDragMap"
          @bounds_changed="startDragMap"
          @idle="endDragMap"
          @zoom_changed="zoomLevelChange"
        >
          <template v-if="mapMoving === false && showBoundryGeofence === true">
            <GmapCircle
              v-for="(geofence, index) in geofencesCircle"
              :key="`geofence-circle-${index}`"
              :center="geofence.Positions[0]"
              :radius="geofence.radius"
              :visible="true"
              :options="{
                fillColor: '#00c246',
                fillOpacity: 0.3,
                strokeColor: '#00c246',
                strokeOpacity: 0.8,
              }"
            ></GmapCircle>

            <GmapPolygon
              v-for="(geofence, index) in geofencesPolygon"
              :key="`geofence-polygon-${index}`"
              :path="geofence.Positions"
              :visible="true"
              :options="{
                fillColor: '#00c246',
                fillOpacity: 0.3,
                strokeColor: '#00c246',
                strokeOpacity: 0.8,
              }"
            />
            <gmap-custom-marker
              v-for="(geofence, index) in geofencesList"
              :key="`geofence-name-${index}`"
              :marker="geofence.Positions[0]"
              alignment="center"
            >
              <div class="geofence-name">
                สถานี
                <br />
                {{ geofence.name }}
              </div>
            </gmap-custom-marker>
          </template>

          <template v-if="mapMoving === false && mapZoom < 16">
            <gmap-marker
              :key="index"
              v-for="(m, index) in geofencesList"
              :position="{
                lat: parseFloat(m.Positions[0].lat),
                lng: parseFloat(m.Positions[0].lng),
              }"
              :clickable="true"
              :draggable="false"
              @mouseover="showByIndex = index"
              @mouseout="showByIndex = null"
              @click="setCenterPath(m)"
            >
              <gmap-info-window :opened="showByIndex === index">
                สถานี {{ m.name }}
              </gmap-info-window>
            </gmap-marker>
          </template>
          <template>
            <cluster
              position="center"
              :zoomOnClick="true"
              :maxZoom="12"
              clickable="true"
              animation="0"
            >
              <template v-for="(vehicle, index) in allVehicles">
                <CustomMarker
                  :key="`${index}-${vehicle.id}`"
                  v-if="
                    vehicle.device &&
                    vehicle.device.position &&
                    'latitude' in vehicle.device.position
                  "
                  :marker="{
                    lat:
                      vehicle.device && vehicle.device.position
                        ? vehicle.device.position.latitude / 10000000
                        : 0,
                    lng:
                      vehicle.device && vehicle.device.position
                        ? vehicle.device.position.longitude / 10000000
                        : 0,
                  }"
                  alignment="center"
                  @click.native.stop="clickVehicle(vehicle)"
                  :mapZoom="mapZoom"
                >
                  <img
                    :class="[
                      ['device-marker', ImgSizeChange(vehicle.vehicle_icon)],
                      selectedVehicle && selectedVehicle.id === vehicle.id
                        ? 'active-vehicle'
                        : '',
                      getFilterClass(
                        vehicle.device && vehicle.device.position
                          ? checkOverSpeed(
                              vehicle.device.position.engineStatus,
                              vehicle.device.position.speed,
                              vehicle.overspeed_limit
                            )
                          : 'ENGINE_STOP'
                      ),
                    ]"
                    :src="carIcon(vehicle.vehicle_icon)"
                    v-bind:style="{
                      transform: getDirectionClass(
                        vehicle.device && vehicle.device.position
                          ? vehicle.device.position.angle
                          : 0
                      ),
                    }"
                  />
                  <div
                    :key="`pulse${vehicle.id}`"
                    :class="[
                      'pulse',
                      selectedVehicle && selectedVehicle.id === vehicle.id
                        ? 'active-vehicle-pulse'
                        : 'inactive-vehicle-pulse',
                    ]"
                  ></div>
                  <div class="device-summary">
                    <h3>{{ vehicle.name }}</h3>
                    <p>
                      {{
                        vehicle.device && vehicle.device.position
                          ? vehicle.device.position.speed
                          : 0
                      }}
                      Km/Hr
                    </p>
                    <p class="vehicle-geolocation" v-if="vehicle.lastLocation">
                      {{
                        `${vehicle.lastLocation.district} ${vehicle.lastLocation.province}`
                      }}
                    </p>
                  </div>
                </CustomMarker>
              </template>
            </cluster>
          </template>
        </GmapMap>
      </div>
      <div class="geofence-list">
        <div class="add-position">
          <geofenceList
            :searchKeyword="searchKeyword"
            @setCenterPath="setCenterPath"
            @deleteGeofence="clickDeleteGeofence"
          />
        </div>
      </div>

      <!-- Bottom drawer for input area -->
      <a-drawer
        placement="bottom"
        :closable="false"
        :mask="false"
        :visible="showGeofenceModal"
        :maskClosable="true"
        :width="256"
        :height="220"
        wrapClassName="add-geofence-drawer"
        :destroyOnClose="true"
      >
        <div>
          <div class="add-position">
            <a-row type="flex" justify="space-around" align="top" :gutter="16">
              <a-col :span="24">
                <h2>{{ geofenceModalTitle }}</h2>
              </a-col>
              <a-col :span="10">
                <a-row>
                  <a-col :span="24">
                    <a-input
                      placeholder="ชื่อสถานี"
                      v-model="geofenceName"
                      style="margin-top: 10px"
                    />
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="24">
                    <a-input
                      v-if="geofenceType != 'polygon'"
                      placeholder="รัศมี"
                      v-model="geofenceRadius"
                      suffix="เมตร"
                      :readOnly="false"
                      :disabled="geofenceType === 'circle' ? true : false"
                      size="small"
                      style="margin-top: 10px"
                    />
                  </a-col>
                  <a-col :span="24"> </a-col>
                </a-row>

                <!-- Cancel and Submit Button row -->
                <a-row>
                  <div style="text-align: left" class="lat_lng-btn-container">
                    <a-button
                      type="default"
                      html-type="submit"
                      style="margin-right: 10px"
                      @click="closeGeofenceCreationModal"
                      >ยกเลิก</a-button
                    >
                    <a-button
                      type="primary"
                      html-type="submit"
                      style="margin-bottom: 10px"
                      :disabled="!validateGeofence"
                      @click="saveGeofence"
                      :loading="saveGeofenceLoading"
                      >บันทึกสถานี</a-button
                    >
                  </div>
                </a-row>
              </a-col>
              <a-col :span="14" style="text-align: left">
                <div class="add-position-container">
                  <h4>
                    {{
                      geofenceType == 'polygon'
                        ? `จำนวนตำแหน่งทั้งหมด ${positionList.length} จุด`
                        : 'ศูนย์กลางสถานี'
                    }}
                  </h4>
                  <div v-if="geofenceType == 'polygon'">
                    <a-popover>
                      <template slot="content">
                        <div
                          :key="`${index}`"
                          v-for="(pos, index) in positionList"
                          class="position"
                        >
                          <a-badge
                            :count="index + 1"
                            :numberStyle="{
                              backgroundColor: '#444444',
                              fontSize: '1.2rem',
                            }"
                          />
                        </div>
                      </template>
                      <a :disabled="positionList.length == 0">
                        ดูรายละเอียดตำแหน่งทั้งหมด</a
                      >
                    </a-popover>
                  </div>
                  <div v-else>
                    <a-input
                      v-model="clickedLat"
                      size="small"
                      :readOnly="false"
                      :disabled="geofenceType === 'circle' ? true : false"
                      placeholder="ละติจูด"
                      @change="
                        () => {
                          this.positionList[0] = {
                            lat: Number(this.clickedLat),
                            lng: Number(this.clickedLng),
                          };
                        }
                      "
                    ></a-input>
                    <a-input
                      v-model="clickedLng"
                      size="small"
                      :readOnly="false"
                      :disabled="geofenceType === 'circle' ? true : false"
                      placeholder="ลองจิจูด"
                      @change="
                        () => {
                          this.positionList[0] = {
                            lat: Number(this.clickedLat),
                            lng: Number(this.clickedLng),
                          };
                        }
                      "
                    ></a-input>
                  </div>
                </div>
                <div v-if="!validateLatLng">
                  <p style="font-size: 16px; color: red">
                    {{ isLatLongErrorMsg }}
                  </p>
                </div>
              </a-col>
            </a-row>
          </div>
        </div>
      </a-drawer>
    </div>
  </defaultLayout>
</template>

<script>
import defaultLayout from '@/views/layouts/defaultLayout.vue';
import geofenceList from '@/views/components/geofence/geofenceList';
import { mapState, mapGetters, mapActions } from 'vuex';
import { getGoogleMapsAPI } from 'vue2-google-maps';
import CustomMarker from '@/views/components/customMarker';
import deviceDrawer from '@/views/components/deviceDrawer';
import { getVehicles } from '@/lib/vehicle';
import { connectPositionSocket } from '@/vue-socket';
import MeasureTool from 'measuretool-googlemaps-v3';

const geolib = require('geolib');

let timeoutSearch = null;
export default {
  name: 'trip',
  components: {
    defaultLayout,
    geofenceList,
    CustomMarker,
    'gmap-custom-marker': CustomMarker,
    deviceDrawer,
  },
  data() {
    return {
      drawingToolsManager: null,
      showByIndex: null,
      selectedShape: null,
      searchText: '',
      searchKeyword: '',
      showBoundryGeofence: false,
      mapMoving: false,
      mapZoom: 12,
      googleMap: {},
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: 0,
        },
      },
      infoWinOpen: true,
      positionList: [],
      clickedLat: '',
      clickedLng: '',
      geofenceType: 'circle',
      geofenceName: '',
      geofenceRadius: 200,
      expandAddGeofence: false,
      showGeofenceModal: false,
      geofencesCircle: [],
      geofencesPolygon: [],
      geofencesList: [],
      boundRect: [],
      activeKeyCollapse: [],

      // For error messages
      isLatLongErrorMsg: '',

      //MD
      measureTool: null,
    };
  },
  watch: {
    activeKeyCollapse(key) {
      if (key.length > 0 && key[0] == 1) {
        this.expandAddGeofence = true;
      } else {
        this.expandAddGeofence = false;
      }
    },
    searchText: function () {
      clearTimeout(timeoutSearch);
      timeoutSearch = setTimeout(() => {
        this.searchKeyword = this.searchText;
      }, 800);
    },
    geofenceData() {
      this.getGeofenceInBound();
      this.setCenterAll();
      this.setGeofenceData();
    },
  },
  computed: {
    ...mapState({
      google: getGoogleMapsAPI,
      selectedGroup: (state) => state.authen.selectedGroup,
      vehicleGroups: (state) => state.vehicle.vehicleGroups,
      user: (state) => state.authen.user,
      saveGeofenceLoading: (state) => state.loading.saveGeofenceLoading,
      geofenceData: (state) => state.geofence.geofences,
      selectedVehicle: (state) => state.vehicle.selectedVehicle,
      allVehicles: (state) => {
        let allV = state.vehicle.vehicleGroups.map((group) => {
          return group.vehicles.filter((vehicle) => {
            return (
              vehicle.device &&
              vehicle.device.imei &&
              vehicle.device.position &&
              vehicle.device.position.latitude
            );
          });
        });
        let flat = [];
        if (allV.length > 0) {
          flat = allV.reduce((a, b) => {
            return a.concat(b);
          }, []);
        }
        return flat;
      },
      ...mapGetters({
        vehicles: 'vehicle/vehicles',
        vehicle: 'vehicle/getSelectedVehicle',
      }),

      validateLatLng() {
        //The Math.abs() function returns the absolute value of a number.
        //The global isFinite() function determines whether the passed value is a finite number. If needed, the parameter is first converted to a number.
        function isLatitude(lat) {
          return isFinite(lat) && Math.abs(lat) <= 90;
        }

        function isLongitude(lng) {
          return isFinite(lng) && Math.abs(lng) <= 180;
        }
        const validLat = isLatitude(this.clickedLat);
        const validLng = isLongitude(this.clickedLng);

        // validLat & validLng conditions
        if (validLat && validLng) {
          this.isLatLongErrorMsg = '';
          return true;
        } else {
          this.isLatLongErrorMsg =
            'ตำแหน่งในรูปแบบละติจูดและลองจิจูดไม่ถูกต้อง';
        }

        return false;
      },
      validateGeofence() {
        if (this.geofenceType == 'polygon') {
          if (this.positionList.length < 3) return false;
        } else {
          if (isNaN(this.geofenceRadius) || this.geofenceRadius <= 0)
            return false;
          if (
            this.clickedLat.length <= 0 ||
            this.clickedLng.length <= 0 ||
            this.validateLatLng == false
          )
            return false;
        }

        if (this.geofenceName.length <= 0) return false;

        return true;
      },
      geofenceModalTitle() {
        if (this.geofenceType == 'circle') {
          return 'รายละเอียดการสร้างสถานีแบบวงกลม';
        }
        if (this.geofenceType == 'circleCustom') {
          return 'รายละเอียดการสร้างสถานีวงกลมแบบกำหนดเอง';
        } else {
          return 'รายละเอียดการสร้างสถานีแบบโพลีกอน';
        }
      },
      showNextPin() {
        if (this.geofenceType == 'circle' && this.positionList.length > 0)
          return false;
        else if (
          this.geofenceType == 'polygon' &&
          this.positionList.length >= 20
        )
          return false;
        return true;
      },
    }),
  },
  sockets: {
    connect: function () {
      console.log('socket connected');
    },
    customEmit: function (data) {
      console.log(
        'this method was fired by the socket server. eg: io.emit("customEmit", data)',
        data
      );
    },
  },
  async mounted() {
    //get vehicles and socket
    if (this.vehicles.length <= 0) {
      await getVehicles(
        this.user.customer_groups.map((group) => {
          return group._id;
        })
      );
    }

    this.getAllGeofences([this.selectedGroup.id]).then(() => {
      this.setGeofenceData();
      this.getGeofenceInBound();
      this.setCenterAll();
    });

    connectPositionSocket();
    this.loadMapDrawingManager();

    //Measure distance
    this.$refs.googleMap.$mapPromise.then((map) => {
      this.measureTool = new MeasureTool(map, {});
    });
  },
  beforeDestroy() {
    this.resetGeofenceState();
  },

  methods: {
    ...mapActions({
      createGeofence: 'geofence/createGeofence',
      deleteGeofence: 'geofence/deleteGeofence',
      getAllGeofences: 'geofence/getAllGeofences',
      setSaveGeofenceLoading: 'loading/setSaveGeofenceLoading',
      resetGeofenceState: 'geofence/resetState',
      selectVehicle: 'vehicle/selectVehicle',
    }),
    checkOverSpeed(enginestatus, speed, overspeed) {
      if (speed >= overspeed) {
        return 'OVER_SPEED';
      } else {
        return enginestatus;
      }
    },
    drawingModeManager(event) {
      let mode = event.key;
      this.resetPositionList();

      //////// GA /////////
      this.$gtag.event(`geofence_${mode}_clicked`);
      ////////////////////

      switch (mode) {
        case 'circleDrawing':
          this.drawingToolsManager.setDrawingMode('circle');
          break;
        case 'polygonDrawing':
          this.drawingToolsManager.setDrawingMode('polygon');
          break;
        case 'circleCustom':
          this.geofenceType = 'circleCustom';
          this.drawingToolsManager.setDrawingMode(null);
          break;
        default:
          break;
      }
      // console.log(this.drawingToolsManager);
      this.showGeofenceModal = true;
    },
    getFilterClass(status) {
      if (status == 'VEHICLE_MOVING') return 'running';
      else if (status == 'ENGINE_IDLE') return 'idle';
      else if (status == 'OVER_SPEED') return 'overspeed';
      else return 'stop';
    },
    getDirectionClass(degree) {
      const direction = degree / 100;
      return `rotate(${direction}deg)`;
    },
    clickVehicle(vehicle) {
      if (!vehicle.device) return;
      this.$refs.googleMap.$mapPromise.then((map) => {
        if (vehicle.device.position && 'latitude' in vehicle.device.position) {
          map.panTo({
            lat: vehicle.device.position.latitude / 10000000,
            lng: vehicle.device.position.longitude / 10000000,
          });
          map.setZoom(16);
        }
      });
      this.clickedLat = vehicle.device.position.latitude / 10000000;
      this.clickedLng = vehicle.device.position.longitude / 10000000;
      this.geofenceName = vehicle.name;
      this.selectVehicle(vehicle);
    },
    async saveGeofence() {
      this.setSaveGeofenceLoading(true);
      let geojson = {};
      if (
        this.geofenceType == 'circle' ||
        this.geofenceType == 'circleCustom'
      ) {
        geojson = {
          type: 'Point',
          coordinates: [
            parseFloat(this.positionList[0].lng),
            parseFloat(this.positionList[0].lat),
          ],
        };
      } else {
        geojson = {
          type: 'Polygon',
          coordinates: [
            this.positionList.map((position) => [
              parseFloat(position.lng),
              parseFloat(position.lat),
            ]),
          ],
        };
      }
      await this.createGeofence({
        name: this.geofenceName,
        type:
          this.geofenceType == 'circleCustom' ? 'circle' : this.geofenceType,
        radius: parseFloat(this.geofenceRadius),
        org: this.selectedGroup.id,
        positions: this.positionList,
        geojson,
      })
        .then((saveResult) => {
          if (saveResult) {
            this.resetPositionList();
            this.getAllGeofences([this.selectedGroup.id]).then(() => {
              this.setGeofenceData();
              this.getGeofenceInBound();
              this.setCenterAll();
            });
            this.$message.success('บันทึกสถานีสำเร็จ', 5);
            this.expandAddGeofence = true;
            this.closeGeofenceCreationModal();
          } else {
            this.$message.warning(
              'ไม่สามารถบันทึกสถานีได้ กรุณาลองอีกครั้ง',
              5
            );
          }
        })
        .catch((err) => {
          console.log(err);
          this.$message.warning('ไม่สามารถบันทึกสถานีได้ กรุณาลองอีกครั้ง', 5);
        });
      await this.setSaveGeofenceLoading(false);
    },
    loadMapDrawingManager() {
      let self = this;
      this.$refs.googleMap.$mapPromise.then(() => {
        // console.log(map, window.google.maps.ControlPosition.TOP_CENTER);
        self.drawingToolsManager =
          new window.google.maps.drawing.DrawingManager({
            drawingControl: false,
            polygonOptions: {
              fillColor: '#0099FF',
              fillOpacity: 0.2,
              strokeColor: '#AA2143',
              strokeWeight: 4,
              editable: true,
            },
            circleOptions: {
              fillColor: '#ffff00',
              fillOpacity: 0.2,
              strokeWeight: 3,
              clickable: false,
              editable: true,
              zIndex: 1,
            },
          });
        // this.drawingToolsManager = drawingManager;
        self.drawingToolsManager.setMap(this.$refs.googleMap.$mapObject);
        window.google.maps.event.addListener(
          self.drawingToolsManager,
          'drawingmode_changed',
          function () {
            // console.log(drawingManager.drawingMode);
            if (self.drawingToolsManager.drawingMode === 'circle') {
              self.geofenceType = 'circle';
            } else if (self.drawingToolsManager.drawingMode === 'polygon') {
              self.geofenceType = 'polygon';
            }
            if (self.selectedShape) {
              self.resetPositionList();
            }
          }
        );

        window.google.maps.event.addListener(
          self.drawingToolsManager,
          'overlaycomplete',
          function (event) {
            // Disable drawingManager
            self.drawingToolsManager.setDrawingMode(null);

            if (event.type !== window.google.maps.drawing.OverlayType.MARKER) {
              let newShape = event.overlay;
              newShape.type = event.type;
              window.google.maps.event.addListener(
                newShape,
                'click',
                function () {
                  self.selectedShape = newShape;
                }
              );

              self.selectedShape = newShape;
            }

            // Create Polygon
            if (event.type === 'polygon') {
              let paths = event.overlay.getPath().getArray();
              self.positionList = [];
              let pts = [];
              paths.forEach((path) => {
                const lat = parseFloat(path.lat().toFixed(6));
                const lng = parseFloat(path.lng().toFixed(6));
                const pt = new window.google.maps.LatLng(lat, lng);
                pts.push(pt);
                self.positionList.push({ lat: lat, lng: lng });
              });

              event.overlay.setMap(null);
              self.createPolygon(pts);
            } else if (event.type === 'circle') {
              let center = [
                event.overlay.center.lat(),
                event.overlay.center.lng(),
              ];
              let radius = event.overlay.radius;
              event.overlay.setMap(null);
              self.drawingToolsManager.setDrawingMode(null);
              self.geofenceRadius = parseInt(radius.toFixed(0));
              self.clickedLat = parseFloat(
                event.overlay.center.lat().toFixed(6)
              );
              self.clickedLng = parseFloat(
                event.overlay.center.lng().toFixed(6)
              );
              self.positionList[0] = {
                lat: self.clickedLat,
                lng: self.clickedLng,
              };
              // Create circle
              self.createCircle(center, radius);
            }
          }
        );
      });
    },
    createPolygon(paths) {
      let self = this;
      this.$refs.googleMap.$mapPromise.then((map) => {
        var polygon = new window.google.maps.Polygon({
          paths: paths,
          fillColor: '#ffff00',
          fillOpacity: 0.2,
          strokeWeight: 3,
          draggable: true,
          editable: true,
        });
        polygon.setMap(map);
        self.selectedShape = polygon;
      });
    },
    createCircle(center, radius) {
      let self = this;
      this.$refs.googleMap.$mapPromise.then((map) => {
        var circle = new window.google.maps.Circle({
          fillColor: '#ffff00',
          fillOpacity: 0.2,
          strokeWeight: 3,
          draggable: true,
          editable: true,
          map: map,
          center: {
            lat: parseFloat(center[0]),
            lng: parseFloat(center[1]),
          },
          radius: radius,
        });

        window.google.maps.event.addListener(
          circle,
          'radius_changed',
          function () {
            // console.log('circle radius changed');
            self.geofenceRadius = parseInt(circle.getRadius().toFixed(0));
          }
        );

        window.google.maps.event.addListener(
          circle,
          'center_changed',
          function () {
            // console.log('circle center changed', circle.getCenter().lat());
            self.clickedLat = parseFloat(circle.getCenter().lat().toFixed(6));
            self.clickedLng = parseFloat(circle.getCenter().lng().toFixed(6));
            self.positionList[0] = {
              lat: self.clickedLat,
              lng: self.clickedLng,
            };
          }
        );
        self.selectedShape = circle;
      });
    },

    clickPosition(e) {
      this.clickedLat = parseFloat(e.latLng.lat());
      this.clickedLng = parseFloat(e.latLng.lng());
      if (this.geofenceType == 'circleCustom')
        this.positionList[0] = {
          lat: Number(this.clickedLat),
          lng: Number(this.clickedLng),
        };
      if (this.validateLatLng) {
        this.$refs.googleMap.$mapPromise.then((map) => {
          map.panTo({
            lat: this.clickedLat,
            lng: this.clickedLng,
          });
        });
      }
    },

    closeGeofenceCreationModal() {
      this.resetPositionList();
      this.drawingToolsManager.setDrawingMode(null);
      this.showGeofenceModal = false;
    },
    resetPositionList() {
      this.clickedLng = '';
      this.clickedLat = '';
      this.geofenceName = '';
      this.geofenceRadius = 0;
      this.positionList = [];
      if (this.selectedShape) {
        this.selectedShape.setMap(null);
        this.selectedShape.setEditable(false);
      }
    },
    setCenterPath(geofence) {
      if (geofence.type == 'circle')
        this.$refs.googleMap.$mapPromise.then((map) => {
          const circle = new window.google.maps.Circle({
            map: map,
            radius: geofence.radius, // 3000 km
            center: {
              lat: parseFloat(geofence.Positions[0].lat),
              lng: parseFloat(geofence.Positions[0].lng),
            },
            fillColor: '#fff',
            fillOpacity: 0.0,
            strokeColor: '#fff',
            strokeOpacity: 0.0,
          });
          var bounds = new window.google.maps.LatLngBounds();
          bounds.union(circle.getBounds());
          map.fitBounds(bounds);
          map.panTo(circle.getBounds().getCenter());
        });
      else
        this.$refs.googleMap.$mapPromise.then((map) => {
          let bounds = new window.google.maps.LatLngBounds();
          for (let i = 0; i < geofence.Positions.length; i++) {
            bounds.extend({
              lat: parseFloat(geofence.Positions[i].lat),
              lng: parseFloat(geofence.Positions[i].lng),
            });
          }
          map.fitBounds(bounds);
          map.panTo(bounds.getCenter());
          map.setZoom(17);
        });
    },
    setCenterAll() {
      this.$refs.googleMap.$mapPromise.then((map) => {
        let bounds = new window.google.maps.LatLngBounds();
        if (this.geofencesList.length > 0) {
          this.geofencesList.forEach((geofence) => {
            if (geofence.type == 'circle') {
              // console.log(geofence.type, geofence.Positions[0])
              const circle = new window.google.maps.Circle({
                map: map,
                radius: geofence.radius, // 3000 km
                center: {
                  lat: parseFloat(geofence.Positions[0].lat),
                  lng: parseFloat(geofence.Positions[0].lng),
                },
                fillColor: '#fff',
                fillOpacity: 0.0,
                strokeColor: '#fff',
                strokeOpacity: 0.0,
              });
              bounds.union(circle.getBounds());
            } else {
              for (let i = 0; i < geofence.Positions.length; i++) {
                // console.log(geofence.type,  geofence.Positions)
                bounds.extend({
                  lat: parseFloat(geofence.Positions[i].lat),
                  lng: parseFloat(geofence.Positions[i].lng),
                });
              }
            }
          });
        } else {
          bounds = [
            { lat: 14.0177193, lng: 100.635164 },
            { lat: 13.6560776, lng: 100.4918588 },
          ];
        }
        map.fitBounds(bounds);
        map.panTo(bounds.getCenter());
      });
    },
    setGeofenceData() {
      this.geofencesCircle = [];
      this.geofencesPolygon = [];
      this.geofencesList = [];
      this.$nextTick(() => {
        // console.log('setGeofenceData', this.geofenceData.length)
        for (const k in this.geofenceData) {
          const geofence = this.geofenceData[k];
          let isIn = false;
          if (this.boundRect.length > 0) {
            if (geofence.type == 'polygon') {
              let centerPolygon = geolib.getCenterOfBounds(geofence.Positions);
              isIn = geolib.isPointInPolygon(
                {
                  latitude: centerPolygon.latitude,
                  longitude: centerPolygon.longitude,
                },
                this.boundRect
              );
            } else {
              isIn = geolib.isPointInPolygon(
                {
                  latitude: geofence.Positions[0].lat,
                  longitude: geofence.Positions[0].lng,
                },
                this.boundRect
              );
            }
          } else {
            isIn = true;
          }
          if (isIn) {
            if (geofence.type == 'circle') {
              this.geofencesCircle.push(geofence);
            } else if (geofence.type == 'polygon') {
              this.geofencesPolygon.push(geofence);
            }
            this.geofencesList.push(geofence);
          }
        }
        return this.geofencesList;
      });
    },
    carIcon(type) {
      let file = 'top-car4';
      if (type == 'truck') {
        file = 'truck';
      }
      if (type == 'sedan') {
        file = 'sedan_new';
      }
      if (type == 'bigTruck') {
        file = 'bigTruck';
      }
      if (type == 'machine') {
        file = 'machine';
      }
      if (type == 'concreteTruck') {
        file = 'concreteTruck';
      }
      if (type == 'trailer') {
        file = 'trailer';
      }
      return require(`../assets/${file}.png`);
    },
    ImgSizeChange(type) {
      // const ChangeSizeType = [null];
      if (type === null) {
        return 'normal-size';
      }
      if (type === 'sedan' || type === 'truck') {
        return 'sedan-truck-size';
      }
      return '';
    },
    getGeofenceInBound(newBoundRect = []) {
      this.geofencesCircle = [];
      this.geofencesPolygon = [];
      this.geofencesList = [];
      // console.log('1 getGeofenceInBound',this.geofenceData.length);
      this.geofenceData.forEach((geofence) => {
        for (const m in geofence.Positions) {
          geofence.Positions[m].lat = parseFloat(geofence.Positions[m].lat);
          geofence.Positions[m].lng = parseFloat(geofence.Positions[m].lng);
        }
        let isIn = false;
        if (newBoundRect.length > 0) {
          if (geofence.type == 'polygon') {
            let centerPolygon = geolib.getCenterOfBounds(geofence.Positions);
            isIn = geolib.isPointInPolygon(
              {
                latitude: parseFloat(centerPolygon.latitude),
                longitude: parseFloat(centerPolygon.longitude),
              },
              newBoundRect
            );
          } else {
            isIn = geolib.isPointInPolygon(
              {
                latitude: geofence.Positions[0].lat,
                longitude: geofence.Positions[0].lng,
              },
              newBoundRect
            );
          }
        } else {
          isIn = true;
        }
        if (isIn) {
          if (geofence.type == 'circle') {
            this.geofencesCircle.push(geofence);
          } else if (geofence.type == 'polygon') {
            this.geofencesPolygon.push(geofence);
          }
          if (
            this.searchKeyword === '' ||
            geofence.name.indexOf(this.searchKeyword) !== -1
          ) {
            this.geofencesList.push(geofence);
          }
        }
      });
    },
    async clickDeleteGeofence(geofenceID) {
      this.setSaveGeofenceLoading(true);
      await this.deleteGeofence(geofenceID)
        .then((deleteResult) => {
          if (deleteResult) {
            // this.getAllGeofences([this.selectedGroup.id]);
            this.getAllGeofences([this.selectedGroup.id]).then(() => {
              this.setGeofenceData();
              this.getGeofenceInBound();
              this.setCenterAll();
            });
            // this.getGeofenceInBound();
            this.$message.success('ลบสถานีสำเร็จ', 5);
          } else {
            this.$message.warning('ไม่สามารถลบสถานีได้ กรุณาลองอีกครั้ง', 5);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$message.warning('ไม่สามารถลบสถานีได้ กรุณาลองอีกครั้ง', 5);
        });
      await this.setSaveGeofenceLoading(false);
    },
    startDragMap() {
      this.mapMoving = true;
    },
    endDragMap() {
      setTimeout(() => {
        if (this.$refs.googleMap && this.$refs.googleMap.$mapObject) {
          // console.log("mapMoving " ,this.mapMoving);
          const neBound = this.$refs.googleMap.$mapObject
            .getBounds()
            .getNorthEast();
          const swBound = this.$refs.googleMap.$mapObject
            .getBounds()
            .getSouthWest();

          const lat0 = neBound.lat();
          const lng0 = neBound.lng();
          const lat1 = swBound.lat();
          const lng1 = swBound.lng();
          const boundRect = [
            { latitude: lat0, longitude: lng0 },
            { latitude: lat0, longitude: lng1 },
            { latitude: lat1, longitude: lng1 },
            { latitude: lat1, longitude: lng0 },
          ];
          if (this.mapMoving === true) {
            this.mapMoving = false;
            this.setGeofenceData();
            this.boundRect = boundRect;
          } else {
            this.boundRect = [];
          }
        }
      }, 500);
    },
    zoomLevelChange(zoomLevel) {
      this.mapZoom = zoomLevel;
      let obj = this;
      setTimeout(() => {
        if (zoomLevel >= 16) {
          obj.showBoundryGeofence = true;
        } else {
          obj.showBoundryGeofence = false;
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
$md: 768px;
$xl: 1024px;
.geofence-creation-btn {
  position: absolute;
  right: 0;
  z-index: 2;
  margin: 10px 10% 0px 0px;
  width: 20%;
  @media screen and (max-width: $xl) {
    left: 0;
    right: 1;
    margin: 18% 0px 0px 10px;
    width: 30%;
  }
}
.google-map {
  width: calc(100vw - 640px);
  display: inline-block;
  position: relative;
  @media screen and (max-width: $md) {
    width: calc(100vw - 320px);
  }
}
.direction-arrow {
  color: #2e8851;
}

.vehicle-drawer {  
  //width: 220px;
  display: inline-block;
  background-color: #fafafa;
  // border: 1px solid #d3d4d6;
  padding: 0 0;
  height: 100vh;
  overflow-y: scroll;
  @media screen and (max-width: $md) {
    width: 120px;
    padding: 5px 5px;
  }
}

.geofence-list {
  padding: 15px 20px;
  width: 280px;
  display: inline-block;
  background-color: #fafafa;
  border: 1px solid #d3d4d6;
  padding: 15px 20px;
  height: 100vh;
  overflow-y: scroll;
  position: absolute;
  right: 0px;
  top: 0px;
}

/** display vehicle position */
.device-marker {
  &.normal-size {
    width: 40px;
  }
  &.sedan-truck-size {
    width: 30px;
  }
  width: 20px;
  position: absolute;
  z-index: 99;
  left: 20px;
}
.active-vehicle-pulse {
  border: 50px solid #00c246;
  background: transparent;
  border-radius: 120px;
  height: 80px;
  width: 80px;
  -webkit-animation: pulse 1s linear;
  -moz-animation: pulse 1s linear;
  animation: pulse 1s linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  z-index: 1;
  opacity: 0;
  // display: block;
  position: absolute;
  top: -30px;
  left: -0px;
}

.inactive-vehicle-pulse {
  display: none;
}

@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(0.75);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

.running {
  // filter: invert(41%) sepia(72%) saturate(1296%) hue-rotate(87deg)
  //   brightness(102%) contrast(111%);
  filter: grayscale(50%) hue-rotate(240deg) saturate(3.3);
}
.idle {
  filter: grayscale(32%) hue-rotate(210deg) saturate(3.8);
}
.stop {
  // filter: invert(56%) sepia(5%) saturate(16%) hue-rotate(136deg) brightness(95%)
  //   contrast(88%);
  filter: grayscale(30%) hue-rotate(153deg) saturate(3.3);
}
.overspeed {
  //filter: invert(24%) sepia(90%) saturate(3678%) hue-rotate(258deg) brightness(65%) contrast(90%);
  filter: grayscale(58%) hue-rotate(68deg) saturate(2.5);
}
</style>

<style lang="scss">
$md: 768px;
.add-geofence-drawer {
  .ant-drawer-wrapper-body {
    overflow: hidden;
  }

  background: #ffffff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: center;
  .ant-drawer-content-wrapper {    

    margin: 0 auto;

    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    .ant-drawer-content {
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      background: #ffffff;
      .ant-drawer-body {
        padding: 15px 24px;
      }
    }

    @media screen and (max-width: $md) {
      width: 560px !important;
    }
    @media screen and (min-width: $md) {
      width: 560px !important;
    }
  }
  .ant-drawer-close {
    color: #bbb;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    // color: #fff;
  }
  h1 {
    font-weight: 700 !important;
  }
}
button.gm-ui-hover-effect {
  display: none !important;
}

.heliot-search-input {
  width: 173px;
  height: 30px;
  background: #eee 0% 0% no-repeat padding-box;
  border-radius: 30px !important;
  border: 1px solid #d9d9d9;
  opacity: 1;
  padding: 15px 20px;
  margin-bottom: 20px;
  @media screen and (max-width: $md) {
    width: 100%;
  }
}

.add-position {
  overflow: hidden;
  input {
    display: inline;
  }
}

.tip-container {
  background-color: #eee;
  padding: 10px 7px 1px 10px;
  margin: 0 0 10px 0;
  border-radius: 5px;
}
.add-position-container {
  background-color: #eee;
  padding: 10px 7px 10px 7px;
  margin: 10px 0 10px 0;
  border-radius: 5px;
  overflow: hidden;
  text-align: left;
  input {
    display: inline-block;
    margin-right: 4px;
    width: 110px;
  }
  button {
    display: inline-block;
  }
}

.all-position-container {
  border: dashed;
  border-width: 1px;
  background-color: #eee;
  padding: 10px 7px 10px 7px;
  // margin: 10px 0 10px 0;
  z-index: 9999;
  border-radius: 5px;
  position: absolute;
  right: 10px;
  top: 180px;
  input {
    display: inline-block;
    margin-right: 4px;
    width: 110px;
  }
  button {
    display: inline-block;
  }
}
.geofence-name {
  font-family: sans-serif;
  font-size: 1.1rem;
  font-weight: 600;
  z-index: 9999;
  text-align: left;
  color: #f5223e;
}

.lat_lng-btn-container {
  margin: 0.75rem 0;
}

//Measure Distance
.segment-measure-text {
  font-size: 1rem;
  pointer-events: none;
  text-shadow: none;

  /* &.inverted {
    stroke: white;
    text-shadow: -2.4px -2.4px $alpha-black, -2.4px 2.4px $alpha-black,
      2.4px 2.4px $alpha-black, 2.4px -2.4px $alpha-black, -2.4px 0 $alpha-black,
      0 2.4px $alpha-black, 2.4px 0 $alpha-black, 0 -2.4px $alpha-black;
  } */
}

.node-measure-text {
  font-size: 1rem;
  pointer-events: none;
  text-shadow: none;

  /* &.inverted {
    stroke: white;
    text-shadow: -2.4px -2.4px $alpha-black, -2.4px 2.4px $alpha-black,
      2.4px 2.4px $alpha-black, 2.4px -2.4px $alpha-black, -2.4px 0 $alpha-black,
      0 2.4px $alpha-black, 2.4px 0 $alpha-black, 0 -2.4px $alpha-black;
  } */

  &.head-text {
    visibility: hidden;
  }
}
</style>
