<template>
  <div class="vehicle-drawer">
    <vehicleStatus pageType="video" />
    <div class="all-vehicle">
      <div>
        <div class="search-bar">
          <a-input
            class="search-input"
            placeholder="ค้นหารถ"
            v-model="keyword"
          />
        </div>
        <h3>รถทั้งหมด</h3>
        <div v-for="vehicle in filterVideoDeviceList()" :key="vehicle.id">
          <vehicleInfoContainer
            :vehicle="vehicle"
            :clickVehicle="clickVehicle"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import vehicleStatus from '@/views/components/vehicleStatus.vue';
import vehicleInfoContainer from '@/views/components/vehicleInfoContainer.vue';
import { mapGetters } from 'vuex';
import moment from '@/lib/time';

export default {
  components: { vehicleStatus, vehicleInfoContainer },
  props: ['clickVehicle'],

  data() {
    return {
      keyword: '',
    };
  },
  computed: {
    ...mapGetters({
      vehicles: 'vehicle/vehicles',
      getFilteredEngineStatus: 'vehicle/getFilteredEngineStatus'
    }),
  },
  mounted() {
    this.filterVideoDeviceList();
  },
  methods: {
    filterVideoDeviceList() {
      return this.vehicles.filter((vehicle) => {
        const vehicleIMEI =
          vehicle.device && vehicle.device.imei ? vehicle.device.imei : '';
        const vehicleChassis = vehicle.chassis_no ? vehicle.chassis_no : '';
        const keywordFilter =
          (
            vehicle.plate_number.toLowerCase() +
            vehicle.name.toLowerCase() +
            vehicleIMEI +
            vehicleChassis.toLowerCase()
          ).search(this.keyword.toLowerCase()) == -1
            ? false
            : true;

          // Check overtime and overspeed value to update engine status
          if (vehicle.device && vehicle.device.position)
              vehicle.device.position.engineStatus = this.checkIsOverSec(
              vehicle.device.position.engineStatus,
              vehicle.device.position.timestamp,
              vehicle.device.position.speed,
              vehicle.overspeed_limit
            );
        const deviceFilter =
          vehicle.device &&
          (vehicle.device.company === 'Richmor' ||
            vehicle.device.company === 'Jimi');
        const engineStatusFilter = this.getFilteredEngineStatus.length === 0 ? deviceFilter : this.getFilteredEngineStatus.includes(vehicle.device.position.engineStatus);
        return keywordFilter && deviceFilter && engineStatusFilter;
      });
    },
    
    checkIsOverSec(engineStatus, timestamp, speed, overspeed) {
      const sec = 3720;
      const overTime = moment().unix() - timestamp;
      if (overTime > sec) {
        return 'NOT_UPDATED';
      } else {
        if (speed >= overspeed) {
          return 'OVER_SPEED';
        }
        return engineStatus;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$md: 768px;
$sm: 450px;

.search-bar > div {
  width: 100%;
}
.search-bar {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  margin-bottom: 12px;
  width: 100%;
  @media screen and (max-width: $md) {
    margin-top: 40;
  }
  @media screen and (max-width: $sm) {
    margin-top: 40px;
  }
}

.search-input {
  width: 100%;
}
.vehicle-drawer {  
  width: 230px;
  display: inline-block;
  background-color: #fafafa;
  // border: 1px solid #d3d4d6;
  //padding: 15px 20px;
  padding: 0 0;
  height: 100vh;
  overflow-y: scroll;
  @media screen and (max-width: $md) {
    width: 120px;
    padding: 5px 5px;
    // padding: 0px 0px;
  }
}
.all-vehicle { 
  padding: 16px 8px;
  @media screen and (max-width: $md) {
    padding: 0px 0px;
  }
}
</style>
