<template lang="">
  <defaultLayout>
    <videoDrawer :clickVehicle="clickVehicle" />
    <div class="content">
      <div class="container">
        <div v-for="(video, index) in videoUrls" :key="index">
          <div class="video-container" :id="video.channel">
            <div v-if="video.url && video.url !== ''" class="video">
              <jessibucaPlayer :selectedVideo="video.url" />
            </div>
            <div v-else class="no-video">
              <div v-if="!loading">No Video on Channel {{ video.channel }}</div>
              <div v-else>
                <a-spin :indicator="indicator" />
                Fetching Video Monitoring Url
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </defaultLayout>
</template>
<script>
import defaultLayout from '@/views/layouts/defaultLayout.vue';
import videoDrawer from '@/views/components/videoMonitoring/videoDrawer.vue';
import jessibucaPlayer from './components/videoMonitoring/jessibucaPlayer.vue';
import endpoints from '../config/config';
import axios from 'axios';
import { mapActions, mapState } from 'vuex';
export default {
  components: {
    defaultLayout,
    videoDrawer,
    jessibucaPlayer,
  },
  data() {
    return {
      videoUrls: [
        {
          channel: 1,
          url: '',
        },
        {
          channel: 2,
          url: '',
        },
        {
          channel: 3,
          url: '',
        },
        {
          channel: 4,
          url: '',
        },
      ],
      loading: false,
      axiosController: null,
      indicator: <a-icon type="loading" style="font-size: 24px" spin />,
    };
  },
  computed: {
    ...mapState({
      selectedVehicle: (state) => state.vehicle.selectedVehicle,
    }),
  },
  methods: {
    ...mapActions({
      selectVehicle: 'vehicle/selectVehicle',
    }),
    async getRealTimeUrl(company, imei) {
      this.axiosController = new AbortController();
      this.loading = true;

      let url = endpoints.richmorApi;

      if (company === 'Jimi') {
        url = endpoints.jimiApi;
      }
      this.closeRealtimeSession();
      await axios
        .get(
          `${url}/realtime-video-url?imei=${imei}&stream_type=1&media_type=0&channels=4`,
          {
            signal: this.axiosController.signal,
          }
        )
        .then((res) => {
          const data = res.data.data;
          this.videoUrls = data;
          this.loading = false;
        })
        .catch((err) => {
          if (err.code && err.code === 'ERR_CANCELED') {
            this.loading = true;
          } else {
            this.loading = false;
          }
          console.log(err);
        });
    },
    async clickVehicle(vehicle) {
      if (!vehicle.device) return;

      this.closeRealtimeSession();

      if (this.axiosController) {
        this.axiosController.abort();
        console.log('request aborted');
      }

      this.selectVehicle(vehicle);
      this.videoUrls = [
        {
          channel: 1,
          url: '',
        },
        {
          channel: 2,
          url: '',
        },
        {
          channel: 3,
          url: '',
        },
        {
          channel: 4,
          url: '',
        },
      ];

      await this.getRealTimeUrl(vehicle.device.company, vehicle.device.imei);
    },
    async clearJimiPlaybackSession() {
      console.log('Clear Jimi Playback Session');
      await axios
        .post(`${endpoints.jimiApi}/clear-playback-session`, {
          license_num: this.selectedVehicle.unit_id,
        })
        .then(() => {
          return true;
        })
        .catch((err) => {
          console.log(err);
          return false;
        });
    },
    async clearRichmorPlaybackSession() {
      console.log('Clear Richmor Playback Session');
      await axios
        .post(`${endpoints.richmorApi}/clear-playback-session`, {
          imei: this.selectedVehicle.unit_id,
        })
        .then(() => {
          return true;
        })
        .catch((err) => {
          console.log(err);
          return false;
        });
    },
    closeRealtimeSession() {
      if (this.selectedVehicle && 'unit_id' in this.selectedVehicle) {
        if (this.selectedVehicle.device.company === 'Richmor') {
          this.clearRichmorPlaybackSession();
        }
        if (this.selectedVehicle.device.company === 'Jimi') {
          this.clearJimiPlaybackSession();
        }
      }
    },
  },
  destroyed() {
    this.selectVehicle({});
    this.closeRealtimeSession();
  },
};
</script>
<style lang="scss">
$md: 768px;

.content {  
  width: calc(100vw - 380px);
  display: inline-block;
  position: absolute;
  height: 100vh;
  overflow-y: hidden;
  margin: 0;
  @media screen and (max-width: $md) {
    width: calc(100vw - 120px);
    overflow-y: scroll;
  }
}
.container {
  display: grid;
  gap: 0.25rem;
  grid-template-columns: repeat(2, 1fr);
  @media screen and (max-width: $md) {
    grid-template-columns: repeat(1, 1fr);
  }
}
.no-video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 1rem;
}

.video-container {
  position: relative;
  width: 100%;
  height: 50vh;
  display: block;
  background-color: black;
}

.video {
  width: 100%;
  height: 50vh;
  display: block;
}
</style>
