<template>
  <div
    :class="[
      'heliot-info-car',
      selectedVehicle.id == vehicle.id ? 'active' : '',
    ]"
    @click="onClickVehicle(vehicle)"
  >
    <div class="InfoAndPTO">
      <div :class="['info', selectedVehicle.id == vehicle.id ? 'active' : '']">
        <div class="vehicle-info">
          <div
            :class="[
              'model',

              vehicle.device && vehicle.device.position
                ? checkisoverSec(
                    vehicle.device.position.engineStatus,
                    vehicle.device.position.timestamp,
                    vehicle.device.position.speed,
                    vehicle.overspeed_limit
                  )
                : '',
            ]"
          >
            <div>
              {{ vehicle.plate_number.substring(0, 8) }}
            </div>
          </div>

          <div class="location clearfix">
            <span
              v-if="
                vehicle.device.position && vehicle.device.position.geofenceName
              "
              style="color: #00c246"
            >
              {{
                vehicle &&
                vehicle.device &&
                vehicle.device.position &&
                vehicle.device.position.geofenceName
              }}
            </span>
            <template v-else>
              {{
                vehicle.lastLocation
                  ? vehicle.lastLocation.district +
                    ' ' +
                    vehicle.lastLocation.province
                  : 'ไม่พบสถานที่'
              }}&nbsp;
            </template>
          </div>
          <div class="name" :title="vehicle.name">{{ vehicle.name }}&nbsp;</div>
        </div>

        <div class="vehicle-speed">
          <div
            :class="[
              'speed',
              vehicle.device && vehicle.device.position
                ? checkisoverSec(
                    vehicle.device.position.engineStatus,
                    vehicle.device.position.timestamp,
                    vehicle.device.position.speed,
                    vehicle.overspeed_limit
                  )
                : '',
            ]"
            v-if="vehicle.device"
          >
            <div class="speed-block">
              <transition name="slide-fade" mode="out-in">
                <div>
                  {{ displayDistance() }}
                </div>
              </transition>
            </div>
            <transition name="slide-fade" mode="out-in">
              <div class="km">กม.</div>
            </transition>
            <span class="km">{{
              vehicle.last_position_json &&
              'startEventTime' in vehicle.last_position_json
                ? timeDuration(vehicle.last_position_json.startEventTime * 1000)
                : '-'
            }}</span>
            <span
              class="km"
              v-if="
                vehicle.device.position && vehicle.device.position.timestamp
              "
              >{{
                `ล่าสุด ${dtFormat(vehicle.device.position.timestamp * 1000)}`
              }}</span
            >
          </div>

          <div :class="['speed']" v-else>
            <a-icon type="disconnect" />
          </div>
        </div>
        <!-- <div class="vehicle-speed-sm">
          <div
            :class="[
              'speed',
              vehicle.device && vehicle.device.position
                ? checkisoverSec(
                    vehicle.device.position.engineStatus,
                    vehicle.device.position.timestamp,
                    vehicle.device.position.speed,
                    vehicle.overspeed_limit
                  )
                : '',
            ]"
            v-if="vehicle.device"
          >
            <div class="speed-block">
              <transition name="slide-fade" mode="out-in">
                <div>
                  {{ parseFloat(distance / 1_000).toFixed(2) }}
                </div>
              </transition>
            </div>
            <transition name="slide-fade" mode="out-in">
              <div class="km">กม.</div>
            </transition>
          </div>

          <div :class="['speed']" v-else>
            <a-icon type="disconnect" />
          </div>
        </div> -->
      </div>

      <template
        v-if="
          (setting.positionPTO &&
            (checkShowPTO(vehicle, 2) ||
              checkShowPTO(vehicle, 3) ||
              checkShowPTO(vehicle, 4))) ||
          (setting.positionTemp &&
            tempSensorIsAvaiable(vehicle.device.position.io)) ||
          (setting.positionFuel &&
            displayFuelLevel(vehicle, vehicle.device.position) != '')
        "
      >
        <div
          class="vehicle-PTO"
          v-if="
            setting.positionPTO &&
            (checkShowPTO(vehicle, 2) ||
              checkShowPTO(vehicle, 3) ||
              checkShowPTO(vehicle, 4))
          "
        >
          <template v-if="checkShowPTO(vehicle, 2)">
            <div
              class="ptoIcon"
              :class="checkShowPTO(vehicle, 2) ? 'ptoBgGreen' : ''"
            >
              <div class="pto-status">PTO1</div>
            </div>
          </template>
          <template v-if="checkShowPTO(vehicle, 3)">
            <div
              class="ptoIcon"
              :class="checkShowPTO(vehicle, 3) ? 'ptoBgGreen' : ''"
            >
              <div class="pto-status">PTO2</div>
            </div>
          </template>
          <template v-if="checkShowPTO(vehicle, 4)">
            <div
              class="ptoIcon"
              :class="checkShowPTO(vehicle, 4) ? 'ptoBgGreen' : ''"
            >
              <div class="pto-status">PTO3</div>
            </div>
          </template>
        </div>
        <div
          class="vehicle-Temp"
          v-if="
            setting.positionTemp &&
            tempSensorIsAvaiable(vehicle.device.position.io)
          "
        >
          <icon-font type="icon-temperature" />{{
            displayTempSensorData(vehicle.device.position.io)
          }}
        </div>
        <div
          class="vehicle-Temp"
          v-if="
            setting.positionTemp &&
            tempSensorIsAvaiable(vehicle.device.position.io, 79)
          "
        >
          ,&nbsp;{{
            displayTempSensorData(vehicle.device.position.io, 79)
          }}&nbsp;
        </div>
        <div
          class="vehicle-Fuel"
          v-if="
            setting.positionFuel &&
            displayFuelLevel(vehicle, vehicle.device.position) != ''
          "
        >
          <icon-font type="icon-water" />{{
            displayFuelLevel(vehicle, vehicle.device.position)
          }}L
        </div>
      </template>
    </div>
    <!-- status card -->
    <div
      class="status_card"
      v-if="
        vehicle.device.position.mcr_license_no && setting.positionActivedCard
      "
    >
      <span style="display: flex; align-items: center">
        <div class="icon_driver_license">
          <icon-font type="icon-drivers-license" />
        </div>
        {{
          displayDriverName(
            vehicle,
            getDriverNameByLicenseNo(vehicle.device.position.mcr_license_no)
          )
        }}
      </span>
      <span style="text-align: right">{{
        vehicle.device.position.mcr_license_no
      }}</span>
    </div>
  </div>
</template>
<script>
import { IconFont } from '@/config/config';
import { mapState, mapGetters } from 'vuex';
import moment from '@/lib/time';
import {
  displayFuelLevel,
  displayTempSensorData,
  displayDriverName,
  tempSensorIsAvaiable,
  tempSensorIsEnable,
} from '@/lib/device';
export default {
  components: {
    IconFont,
  },
  props: {
    vehicle: Object,
    distance: Number,
    onClickVehicle: Function,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      driverByLicenseNo: 'driver/driverByLicenseNo',
    }),
    ...mapState({
      selectedVehicle: (state) => state.vehicle.selectedVehicle,
      setting: (state) => state.authen.setting,
    }),
  },
  methods: {
    displayDistance() {
      if (!Number.isNaN(this.distance)) {
        return Math.trunc(this.distance / 1_000) > 10
          ? Math.trunc(this.distance / 1_000)
          : parseFloat(this.distance / 1_000).toFixed(2);
      } else {
        return '-';
      }
    },
    timeDuration(initTime) {
      let a = moment(initTime);
      let b = moment();
      let dif = b.diff(a);
      let d = moment.duration(dif);
      if (d.isValid()) {
        return d.days() >= 1
          ? `${d.days()} วัน ${d.hours()} ชม.`
          : d.hours() >= 1
          ? `${d.hours()} ชม. ${d.minutes()} นาที`
          : `${d.minutes()} นาที`;
      } else {
        return '-';
      }
    },
    checkShowPTO(vehicle, ioNo) {
      if (
        vehicle.device.position &&
        vehicle.device.position.io &&
        ioNo in vehicle.device.position.io &&
        vehicle.device.position.io[ioNo] == 1
      ) {
        return true;
      } else {
        return false;
      }
    },
    dtFormat(timestamp) {
      if (timestamp) {
        return moment(timestamp, 'x').format('HH:mm:ss');
      } else {
        return '-';
      }
    },
    displayTempSensorData(io, no) {
      const tempDisplay = displayTempSensorData(io, no);
      if (tempDisplay != '-') {
        return tempDisplay + '°';
      }
      return '';
    },
    tempSensorIsAvaiable(io, no) {
      return tempSensorIsAvaiable(io, no);
    },
    tempSensorIsEnable(io) {
      return tempSensorIsEnable(io);
    },
    getDriverNameByLicenseNo(licenseNo) {
      return this.driverByLicenseNo(licenseNo);
    },
    displayDriverName(vehicle, getDriverNameByLicenseNo) {
      return displayDriverName(false, vehicle, getDriverNameByLicenseNo);
    },
    //add oversec function
    checkisoverSec(engineStatus, timestamp, speed, overspeed) {
      const sec = 3720;
      const overTime = moment().unix() - timestamp;
      if (overTime > sec) {
        return 'NOT_UPDATED';
      } else {
        if (speed >= overspeed) {
          return 'OVER_SPEED';
        }
        return engineStatus;
      }
    },

    displayFuelLevel(vehicle, position) {
      return displayFuelLevel(vehicle, position);
    },
  },
};
</script>
<style lang="scss" scoped>
$md: 768px;

.heliot-info-car {
  &.active {
    background: #ddd 0% 0% no-repeat padding-box;
    border: 1px solid #513fe8;
    cursor: pointer;
  }
  &:hover {
    background: #ddd 0% 0% no-repeat padding-box;
    cursor: pointer;
  }

  background: none;
  margin: 7px 0px;
  border-radius: 7px;
  background: rgba(0, 0, 0, 0.13) 0% 0% no-repeat padding-box;
  @media screen and (max-width: $md) {
    width: 100%;
  }
}

.InfoAndPTO {
  background: #e5e5e5;
  border-radius: 7px;
}
.status_card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 15px;
  font-size: 13px;
  color: #5a5a5a;
  margin: 3px 3px 0px 6px;
  padding-bottom: 2.5px;
}
.icon_driver_license {
  float: left;
  font-size: 22px;
  margin: 0px 4px 0px 0px;
}

.ptoBgGreen {
  background: #00c246 !important;
}
.ptoIcon {
  margin: 3px 1px;
  width: 30px;
  background: #aaa;
  display: inline-block;
  border-radius: 10px 10px 10px 10px;
  font-size: 10px;
}
.info {
  &.active {
    background: #ddd 0% 0% no-repeat padding-box;
    cursor: pointer;
  }
  &:hover {
    background: #ddd 0% 0% no-repeat padding-box;
    cursor: pointer;
  }
  border-radius: 7px;
  background: #eee;
  height: 68px;
}
.vehicle-PTO {
  font-size: 12px;
  font-weight: 200;
  border-radius: 0 0 7px 7px;
  width: auto;
  display: inline-block;
  .pto-status {
    font-weight: 300;
    padding: 2px 1px;
    color: #fff;
    text-align: center;
  }
}
.vehicle-Temp {
  width: 44px;
  display: inline-block;
  padding: 3px 0 0 0;

  color: #333;
  text-align: left;
}
.vehicle-Fuel {
  width: 50px;
  display: inline-block;
  padding: 3px 0 0 0;

  color: #333;
  text-align: left;
}
.vehicle-info {
  width: 65%;
  text-align: left;
  display: inline-block;
  padding: 5px 5px 0px 5px;

  @media screen and (max-width: $md) {
    width: 109px;
    .model {
      font-size: 18rem;
      font-weight: 200;
      color: #505050;
      height: 20px;
      line-height: 20px;
      white-space: nowrap;
      margin-top: 7px !important;
      &.ENGINE_STOP {
        color: #f5223e;
      }
      &.ENGINE_IDLE,
      .ENGINE_IDLE_END {
        color: #faad14;
      }
      &.OVER_SPEED {
        color: #8e34ff;
      }
      &.VEHICLE_MOVING {
        color: #00c246;
      }
      &.NOT_UPDATED {
        color: #aaa;
      }
    }
  } //ทะเบียน
  @media screen and (max-width: $md) {
    width: 80px;
    .model {
      font-size: 18rem;
      font-weight: 200;
      color: #505050;
      height: 20px;
      line-height: 20px;
      white-space: nowrap;
      margin-top: 3px !important;
      &.ENGINE_STOP {
        color: #f5223e;
      }
      &.ENGINE_IDLE,
      .ENGINE_IDLE_END {
        color: #faad14;
      }
      &.OVER_SPEED {
        color: #8e34ff;
      }
      &.VEHICLE_MOVING {
        color: #00c246;
      }
      &.NOT_UPDATED {
        color: #aaa;
      }
    }
  }
  .model {
    font-size: 18px;
    font-weight: 200;
    color: #505050;
    height: 20px;
    line-height: 20px;
    white-space: nowrap;
    margin-top: 0px;
    &.ENGINE_STOP {
      color: #f5223e;
    }
    &.ENGINE_IDLE,
    .ENGINE_IDLE_END {
      color: #faad14;
    }
    &.OVER_SPEED {
      color: #8e34ff;
    }
    &.VEHICLE_MOVING {
      color: #00c246;
    }
    &.NOT_UPDATED {
      color: #aaa;
    }
  }
  .name {
    font-size: 13px;
    color: #505050;
    height: 10px;
    line-height: 12px;
    white-space: nowrap;
    vertical-align: middle;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 3px 0 0 0;
  }
}

.location {
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-top: 3px;
}

.vehicle-speed-sm {

  float: right;
  padding-right: 2px;
  text-align: right;
  letter-spacing: 0;
  color: #444;
  opacity: 1;
  font-size: 25px;
  margin-top: 6px;
  .speed {
    height: 22px;
    line-height: 12px;
    font-weight: 300;
    &.ENGINE_STOP {
      color: #f5223e;
    }
    &.ENGINE_IDLE,
    .ENGINE_IDLE_END {
      color: #faad14;
    }
    &.OVER_SPEED {
      color: #8e34ff;
    }
    &.VEHICLE_MOVING {
      color: #00c246;
    }
    .km {
      margin-top: 3px;
      font-size: 0.7rem;
      font-weight: normal;
      display: block;
      height: 0.6rem;
      line-height: 0.2rem;
    }
    &.NOT_UPDATED {
      color: #aaa;
    }
  }
  .lastupdate {
    text-align: right;
    font-weight: 300;
    letter-spacing: 0;
    color: #505050;
    font-size: 12px;
    height: 30px;
    line-height: 30px;
  }
  .speed-block {
    margin: 3px 0px 0 0;
    display: block;
    overflow: hidden;
    height: 18px;
    line-height: 16px;
    padding: 0px;
    text-align: right;
    span {
      text-align: right;
    }
  }
  @media screen and (min-width: $md) {
    display: none;
  }
}
.vehicle-speed {
  float: right;
  padding-right: 5px;
  text-align: right;
  letter-spacing: 0;
  color: #444;
  opacity: 1;
  font-size: 32px;
  margin-top: 2px;
  .speed {
    height: 30px;
    line-height: 12px;
    font-weight: 300;
    &.ENGINE_STOP {
      color: #f5223e;
    }
    &.ENGINE_IDLE,
    .ENGINE_IDLE_END {
      color: #faad14;
    }
    &.OVER_SPEED {
      color: #8e34ff;
    }
    &.VEHICLE_MOVING {
      color: #00c246;
    }
    .km {
      margin-top: 3px;
      font-size: 0.7rem;
      font-weight: normal;
      display: block;
      height: 0.6rem;
      line-height: 0.2rem;
    }
    &.NOT_UPDATED {
      color: #aaa;
    }
  }
  .lastupdate {
    text-align: right;
    font-weight: 300;
    letter-spacing: 0;
    color: #505050;
    font-size: 12px;
    height: 30px;
    line-height: 30px;
  }
  @media screen and (max-width: $md) {
    display: none;
  }
}
.speed-block {
  margin: 3px 0px 0 0;
  display: block;
  overflow: hidden;
  height: 22px;
  line-height: 18px;
  padding: 0px;
  text-align: right;
  span {
    text-align: right;
  }
}
.slide-fade-enter-active {
  transition: all 0.6s ease-out;
}
.slide-fade-leave-active {
  transition: all 0.6s ease-out;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(15px);
  // transform: rotateX(180deg);
  opacity: 1;
}
.slide-fade-leave,
.slide-fade-leave-to {
  transform: translateY(-15px);
  opacity: 0;
}
</style>
