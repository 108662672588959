<template>
  <a-layout id="components-layout-demo-responsive ">
    <!-- <modal
      name="survey-modal"
      :classes="['survey-modal']"
      width="100%"
      height="100%"
      :closable="true"
      :centered="true"
    >
      <a
        target="_blank"
        href="https://docs.google.com/forms/d/e/1FAIpQLSezW_Y1nuiGM4Olpv977n8LFivdDSTAyFHjmX5VW-hPbOYpzA/viewform"
        ><img
          :src="require('../../assets/heliot_fleet_survey_banner_2023.png')"
      /></a>
      <div class="message">
        <div class="desc">
          <p style="text-align: center; margin-top: 20px">
            <a
              target="_blank"
              href="https://docs.google.com/forms/d/e/1FAIpQLSezW_Y1nuiGM4Olpv977n8LFivdDSTAyFHjmX5VW-hPbOYpzA/viewform"
            >
              <a-button type="primary" class="heliot-btn" size="large">
                เริ่มทำแบบสอบถาม
              </a-button></a
            >

            <a-button
              type="primary"
              class="heliot-btn"
              size="large"
              @click="$modal.hide('survey-modal')"
            >
              ปิดหน้านี้
            </a-button>
          </p>
        </div>
      </div>
    </modal> -->
    <!-- <modal
      name="tms-landing-modal"
      :classes="['feature-modal']"
      width="320px"
      height="520px"
    >
      <img :src="require('../../assets/tms.png')" />
      <div class="message">
        <h3>เราขอแนะนำ</h3>
        <h2>ระบบจัดการงาน (TMS)</h2>
        <div class="desc">
          <p>
            ติดต่อพนักงานขายที่ดูแลท่านหรือที่ contact@heliot.co.th
            เพื่อทดลองใช้งาน
          </p>
          <p style="text-align: center">
            <router-link :to="{ name: 'TMS' }"
              ><a-button type="primary" class="heliot-btn" size="large">
                ทดลองใช้งาน
              </a-button></router-link
            >
          </p>
        </div>
      </div>
    </modal> -->
    <!-- <modal
      name="maintenance-modal"
      :classes="['feature-modal']"
      width="360px"
      height="570px"
    > 
   
      <img :src="require('../../assets/maintenance.png')" />
      <div class="message">
        <h3>Heliot is getting better and faster.</h3>
        <h2>แจ้งปิดปรับปรุงแอพพลิเคชั่น</h2>
        <h4>วันเสาร์ที่ 20 มค. 2567 เวลา 22.00 - 00.00น.</h4>
        <div class="desc">
          <p>
            เว็บไซต์ https://fleet.heliot.co.th
            จะใช้งานไม่ได้สำหรับบางหน้าในช่วงเวลาดังกล่าว
            การปิดปรับปรุงจะ<strong>ไม่มีผลกระทบ </strong
            >ต่อการส่งข้อมูลไปยังกรมขนส่งทางบก
          </p>
          <p></p>
          <p>ขออภัยในความไม่สะดวก</p>
        </div>
      </div> 
    </modal> -->
    <modal
      name="cloud-maintenance-migrate-alert"
      :width="isMobileDevice ? '300px' : '900px'"
      :height="isMobileDevice ? '533px' : '675px'"
    >
      <img
        :src="
          require(`@/assets/maintenance/${
            isMobileDevice ? 'mobile' : 'desktop'
          }.png`)
        "
        :style="{
          width: '100%',
          height: '100%',
          aspectRatio: isMobileDevice ? '16 / 9' : '4 / 3',
          objectFit: 'cover',
        }"
      />
    </modal>
    <!-- Debt modal -->
    <modal
      name="debt-modal"
      :classes="['debt-modal']"
      :closable="true"
      :clickToClose="false"
      :centered="true"
      @closed="handleCloseDebtModal"
    >
      <div class="debt-modal-container">
        <img
          :src="require('../../assets/close.svg')"
          @click="handleCloseDebtModal"
          alt=""
          class="debt-modal-close-btn"
        />
        <div class="debt-modal-content">
          <h1 class="debt-modal-header-txt">คุณมียอดค้างชำระเกินกำหนด</h1>
          <p class="debt-modal-detail-txt">
            ขออภัยในความไม่สะดวก เพื่อการใช้งานได้อย่างต่อเนื่อง
            และป้องกันการระงับการใช้งาน กรุณาชำระค่าบริการ โทร 02-010-6990 กด 3
            หรือ 090-992-4761
          </p>
          <button
            type="button"
            name="contact-staff-btn"
            class="debt-modal-contact-staff-btn"
          >
            <a href="tel:+66020106990">ติดต่อเจ้าหน้าที่</a>
          </button>
          <div class="debt-modal-bottom-txt">
            บริษัทขออภัยหากท่านชำระค่าบริการแล้ว
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="pdpa-modal"
      :classes="['pdpa-modal']"
      :clickToClose="false"
      :closable="false"
      :centered="true"
    >
      <div class="pdpa-modal-content">
        <div class="title">
          กรุณายอมรับเงื่อนไขการใช้งาน<br />ข้อมูลส่วนบุคคล และการใช้งานคุกกี้
        </div>
        <div class="body">
          <div class="context">
            <div>
              <div style="text-align: center; font-size: 20px">
                นโยบายคุ้มครองข้อมูลส่วนบุคคล
              </div>
              <p>
                เพื่อปกป้องสิทธิของผู้ใช้บริการ
                โปรดอ่านเงื่อนไขการให้บริการทั้งหมดและตกลงที่จะปฏิบัติตามข้อตกลงและเงื่อนไขทั้งหมดต่อไปนี้
              </p>
            </div>
            <p>
              ข้อ 1 ขอบเขตของข้อตกลงและเงื่อนไขการใช้บริการ<br />
              ข้อตกลงต่อไปนี้ (“ข้อตกลงการใช้งาน”)
              จะเป็นข้อตกลงการใช้ระหว่างคุณกับแอปพลิเคชัน Dinoculars
              ซึ่งรวมถึงเนื้อหา การใช้งาน และการให้บริการทั้งหมดบนแอปพลิเคชัน
              Dinoculars บนระบบปฏิบัติการ Android และ iOS ที่คุณต้องปฏิบัติตาม
              โดยการเข้าถึงหรือเข้าใช้บริการแอปพลิเคชัน Dinocular
              ภายใต้การควบคุมดูแลของบริษัท เฮลลิออท จำกัด (Heliot Co.,Ltd)
              จะหมายถึง ท่านยอมรับข้อตกลง ข้อกำหนดและเงื่อนไขการใช้บริการ
              ที่ปรากฏบนแอปพลิเคชันว่ามีผลผูกพันตามกฎหมาย (“เงื่อนไข”)
              และท่านตกลงยอมรับ และปฏิบัติตาม
              นโยบายความเป็นส่วนตัวของแอปพลิเคชันและยอมรับที่จะปฏิบัติตามกฎระเบียบอื่นๆ
              บนแอปพลิเคชัน เช่น วิธีการใช้
              วิธีปฏิบัติและขั้นตอนหรือเงื่อนไขสำหรับการเข้าถึงหรือการใช้บริการของแอปพลิเคชัน
              Dinoculars
            </p>
            <p>
              ข้อ 2 การยอมรับข้อตกลงและเงื่อนไขการใช้บริการ<br />
              ผู้ใช้บริการจะต้องตกลงและปฏิบัติตามเงื่อนไข
              ซึ่งปรากฏอยู่บนเงื่อนไขการเป็นสมาชิกบริการ
              และเงื่อนไขการใช้บริการฉบับนี้
              หรือในฉบับแก้ไขเพิ่มเติมอันอาจจะมีขึ้นในภายหน้า
              และการที่ท่านได้ตกลงในการใช้บริการของ เฮลลิออท จำกัด
              ถือว่าท่านได้ยินยอมและเห็นด้วยกับข้อตกลงและเงื่อนไขการให้บริการฉบับนี้แล้ว
              <br />
              2.1
              เงื่อนไขการเป็นสมาชิกและเงื่อนไขการให้บริการแอปพลิเคชันฉบับนี้เป็นไปตามตามเงื่อนไขการเป็นสมาชิก
              และเงื่อนไขการให้บริการแอปพลิเคชันของสัญญาการให้บริการแอปพลิเคชันโทรศัพท์มือถือ
              หากผู้ใช้บริการทำการตอบคำถาม
              ถือว่าผู้ใช้บริการได้ยอมรับเงื่อนไขการให้บริการ
              และถือว่าเงื่อนไขการเป็นสมาชิก
              และเงื่อนไขการให้บริการแอปพลิเคชันนี้มีผลบังคับใช้ <br />2.2
              เฮลลิออท จำกัด สามารถเปลี่ยนแปลงตามเงื่อนไขการเป็นสมาชิก
              และเงื่อนไขการให้บริการได้ตามความเหมาะสม
              ซึ่งเงื่อนไขการให้บริการที่มีการเปลี่ยนแปลงจะถูกประกาศให้ทราบบนแอปพลิเคชัน
              Dinoculars<br />
              2.3 ผู้ใช้บริการมีหน้าที่ต้องเข้าไปเยี่ยมชมแอปพลิเคชันเป็นระยะ
              พร้อมตรวจสอบเนื้อหาข้อตกลงที่มีการเปลี่ยนแปลง และได้ประกาศไว้แล้ว
              หากผู้ใช้บริการได้มีการเข้าใช้บริการหลังจากมีการประกาศเปลี่ยนแปลงแล้ว
              ถือว่าท่านได้ยอมรับและเห็นด้วยในการเปลี่ยนแปลงเนื้อหาดังกล่าวนั้น
              และทาง เฮลลิออท จำกัด จะไม่รับผิดชอบต่อความเสียหายใดๆ
              ของผู้ใช้บริการ
              อันเกิดจากการไม่ทราบเนื้อหาประกาศของข้อตกลงนั้นๆ<br />
              2.4 ผู้ใช้บริการ จะต้องขอลงทะเบียนเป็นผู้ใช้บริการ
              โดยกรอกข้อมูลชื่อ-นามสกุล และข้อมูลส่วนตัวตามความเป็นจริง
              ของผู้ใช้บริการ
              และดำเนินตามขั้นตอนการขอเป็นผู้ใช้บริการด้วยข้อมูลที่เป็นความจริงทุกประการ
            </p>
            <p>
              ข้อ 3 รายละเอียดการบริการ
              การบริการให้หมายความรวมถึงการให้บริการดังต่อไปนี้ด้วย<br />
              3.1 การดาวน์โหลดแอปพลิเคชัน Dinoculars
              และดำเนินการติดตั้งอุปกรณ์จีพีเอส
              เพือเป็นระบบติดตามยานพาหนะโดยความยินยอมของผู้ใช้บริการ <br />3.2
              เฮลลิออท จำกัด เป็นผู้เผยแพร่แอปพลิเคชันบน โทรศัพท์มือถือ ที่
              เฮลลิออท จำกัด ได้ผลิต <br />3.3 เฮลลิออท จำกัด
              เป็นผู้ให้บริการระบบติดตามจีพีเอส
            </p>
            <p>
              ข้อ 4 เนื้อหาการใช้บริการ <br />เฮลลิออท จำกัด
              จะไม่รับผิดชอบการเก็บรักษาข้อมูลใดๆ
              หรือข้อมูลการใช้งานของผู้ใช้บริการ
              ที่ใช้บริการอย่างผิดวัตถุประสงค์
              หรือผิดเงื่อนไขการให้บริการหรือผิดกฎหมายไทย
              หรือกฎหมายระหว่างประเทศที่ใช้บังคับ และเป็นที่ยอมรับของนานาประเทศ
              <br />
              4.1 เฮลลิออท จำกัด
              จะให้บริการตามเงื่อนไขการให้บริการหรือตามกฎหมายไทย
              หรือกฎหมายระหว่างประเทศที่ใช้บังคับ และเป็นที่ยอมรับของนานาประเทศ
              เพื่อให้บริการแอปพลิเคชันที่ เฮลลิออท จำกัด ได้ผลิต
              หรือได้รับสิทธิ์ให้แก่ผู้ใช้บริการ <br />4.2 ในกรณีที่ เฮลลิออท
              จำกัด
              มีความจำเป็นต้องเพิ่มเนื้อหาการให้บริการแอปพลิเคชันหรือเพื่อความจำเป็นในการบริหารแอปพลิเคชันผู้ใช้บริการตกลงยินยอมให้
              เฮลลิออท จำกัด มีสิทธิ์เปลี่ยนแปลงได้เป็นครั้งๆไป
              ทั้งโดยการอัพเดทออนไลน์และออฟไลน์<br />
              4.3 เฮลลิออท จำกัด
              จะประกาศเงื่อนไขการให้บริการแอปพลิเคชันและข้อกำหนดหรือข้อบังคับใดๆ
              ที่เกี่ยวข้องกับกฎหมายไทยหรือกฎหมายระหว่างประเทศที่ใช้บังคับและเป็นที่ยอมรับของนานาประเทศ
              เกี่ยวกับเงื่อนไขการให้บริการแอปพลิเคชันอย่างสม่ำเสมอ
              ผู้ใช้บริการตกลงและให้ความยินยอมกับเงื่อนไขการให้บริการแอปพลิเคชันอัพเดทกิจกรรมหรือฟังก์ชั่นที่ให้บริการทุกประการ
              ทั้งนี้ผู้ใช้บริการ
              จะต้องตรวจสอบประกาศเงื่อนไขการให้บริการแอปพลิเคชันและข้อกำหนดหรือข้อบังคับใดๆ
              ที่เกี่ยวข้องกับกฎหมายไทยหรือกฎหมายระหว่างประเทศที่ใช้บังคับและเป็นที่ยอมรับของนานาประเทศ
              เกี่ยวกับเงื่อนไขการให้บริการแอปพลิเคชันอย่างสม่ำเสมอ เฮลลิออท
              จำกัด จะไม่รับผิดชอบความเสียหายใดๆ ของผู้ใช้บริการ
              หากไม่ได้เข้ามาตรวจสอบประกาศเงื่อนไขการให้บริการแอปพลิเคชันและข้อกำหนดหรือข้อบังคับใดๆ
              ที่เกี่ยวข้องกับกฎหมายไทยหรือกฎหมายระหว่างประเทศที่ใช้บังคับและเป็นที่ยอมรับของนานาประเทศ
              เกี่ยวกับเงื่อนไขการให้บริการแอปพลิเคชัน
            </p>
            <p>
              ข้อ 5 บัญชี<br />
              5.1 เมื่อใช้บริการฯ
              ผู้ใช้อาจมีความจำเป็นต้องลงทะเบียนการใช้บริการฯ
              ด้วยข้อมูลบางประการ ทั้งนี้ ผู้ใช้ต้องให้ข้อมูลที่เป็นความจริง
              ถูกต้อง
              ครบถ้วนและมีหน้าที่ต้องปรับปรุงและแก้ไขข้อมูลดังกล่าวให้เป็นปัจจุบันอยู่เสมอ
              <br />
              5.2 ในกรณีที่ผู้ใช้ลงทะเบียนข้อมูลการยืนยันตัวตนใดๆ
              (Authentication information) เมื่อใช้บริการฯ
              ผู้ใช้ต้องใช้ความระมัดระวังในการจัดการข้อมูลดังกล่าวด้วยความรับผิดชอบของตน
              เพื่อให้เป็นที่แน่ใจว่าข้อมูลดังกล่าวจะไม่ถูกนำไปใช้ในลักษณะที่ไม่ชอบด้วยกฎหมาย
              ทั้งนี้ เฮลลิออท จำกัด อาจถือว่ากิจกรรมใดๆ
              ซึ่งดำเนินการโดยการใช้ข้อมูลการรับรองตัวตนดังกล่าว
              เสมือนเป็นกิจกรรมที่ผู้เป็นเจ้าของข้อมูลได้ดำเนินการด้วยตนเองทั้งสิ้น
              <br />
              5.3 เฮลลิออท จำกัด ขอสงวนสิทธิในการลบบัญชีใดๆ
              ซึ่งไม่มีการเปิดใช้งานเป็นเวลากว่า หนึ่ง (1) ปี
              หรือนานกว่านับแต่วันที่มีการเปิดใช้งานบัญชีดังกล่าวครั้งล่าสุด
              ทั้งนี้ โดยไม่ต้องบอกกล่าวล่วงหน้าใดๆ แก่ผู้ใช้บัญชีดังกล่าว
              <br />5.4 สิทธิใดๆ
              ของผู้ใช้บริการอาจสิ้นสุดลงเมื่อบัญชีของผู้ใช้ดังกล่าวถูกลบไม่ว่าด้วยเหตุผลประการใดๆ
              ทั้งนี้ บัญชีการใช้บริการฯ
              จะไม่สามารถกู้คืนได้ถึงแม้ว่าผู้ใช้บริการฯ
              จะลบบัญชีของตนโดยไม่ได้ตั้งใจก็ตาม <br />5.5
              บัญชีแต่ละบัญชีในการใช้บริการฯ นั้น
              มีไว้เพื่อการใช้งานเฉพาะบุคคลและเป็นของเจ้าของบัญชีนั้นแต่เพียงผู้เดียว
              ผู้ใช้ไม่สามารถโอน ให้ยืม
              หรือจำหน่ายสิทธิในการใช้บัญชีของตนแก่บุคคลภายนอก
              ขณะเดียวกันบุคคลภายนอกก็ไม่สามารถรับช่วงสิทธิหรือสืบทอดบัญชีจากผู้ใช้ดังกล่าวได้เช่นกัน
            </p>
            <p>
              ข้อ 6 การใช้และการป้องกันข้อมูลส่วนบุคคล<br />
              6.1 เฮลลิออท จำกัด มีสิทธิ์ใช้ข้อมูลของผู้ใช้บริการ
              เพื่อการใช้งานด้านต่างๆ ทางการตลาด โฆษณา ประชาสัมพันธ์ ของ
              เฮลลิออท จำกัด ได้ โดยชื่อบัญชี ชื่อ-ชื่อสกุล ของผู้ใช้บริการ
              เฮลลิออท จำกัด จะสามารถเปิดเผยได้ในวัตถุประสงค์
              เพื่อประโยชน์สาธารณะตามกฎหมายไทยและกฎหมายนานาประเทศที่ใช้บังคับ<br />
              6.2 เฮลลิออท จำกัด
              สงวนสิทธิ์ในการบันทึกเนื้อหาที่เกิดขึ้นภายในแอปพลิเคชัน
              และสามารถเรียกดูได้ในกรณีที่ เฮลลิออท จำกัด พิจารณาเห็นว่าจำเป็น
              กรณีเกิดข้อโต้แย้ง/ข้อพิพาท
              และเพื่อรักษาระเบียบและเงื่อนไขในการใช้บริการภายในแอปพลิเคชัน
              ทั้งนี้ เฮลลิออท จำกัด
              จะควบคุมด้วยการจัดการแบบใช้เทคนิคมากที่สุดเท่าที่จะทำได้
              ข้อมูลดังกล่าว เฮลลิออท จำกัดจะเป็นผู้จัดเก็บแต่เพียงผู้เดียว<br />
              6.3 Dinoculars
              จะบันทึกและเก็บรักษาเนื้อหาทุกอย่างที่เกิดขึ้นระหว่างผู้ใช้บริการภายในแอปพลิเคชัน
              ดังนี้<br />
              6.3.1 ทะเบียนและข้อมูลพาหนะอื่นๆ<br />
              6.3.2 ข้อมูลส่วนตัวของผู้ใช้บริการ<br />
              6.3.2 ประวัติการใช้งานแอปพลิเคชัน<br />
              6.3.3
              ประวัติข้อมูลที่ถูกสร้างขึ้นโดยอุปกรณ์ติดตามจีพีเอสในระหว่างการใช้งาน
            </p>
            <p>
              ข้อ 7 ความรับผิดชอบและหน้าที่ของผู้ใช้บริการ<br />
              7.1 มีหน้าที่และความรับผิดอื่นๆ ตามกฎหมายที่ใช้บังคับ <br />7.2
              ไม่กระทำผิดตามข้อกฎหมายใดๆ
              รวมถึงไม่ละเมิดในทรัพย์สินทางปัญญาหรือสิทธิอื่นในรูปแบบใดๆ
              ทั้งสิ้น
              <br />
              7.3 ปฏิบัติตามข้อตกลงและเงื่อนไขการใช้บริการนี้ หรือกฎหมายอื่นๆ
              ที่เกี่ยวข้อง <br />7.4 ไม่ประพฤติตนซึ่งเป็นอุปสรรคต่อการทำงาน
              และการให้บริการแอปพลิเคชันของ เฮลลิออท จำกัด <br />7.5
              ไม่มีพฤติกรรมฉ้อโกงต่อ เฮลลิออท จำกัด<br />
              7.6 หากผู้ใช้บริการพบว่ามีการใช้บริการที่ผิดข้อตกลง
              และเงื่อนไขการให้บริการนี้หรือผิดกฎหมายใดๆ
              โดยบัญชีหรือรหัสผ่านซึ่งเป็นของผู้ใช้บริการ ให้ผู้ใช้บริการแจ้ง
              เฮลลิออท จำกัด ทราบทันที
              หากพบว่าการใช้บริการนั้นเกิดจากความประมาทเลินเล่อของผู้ใช้บริการเอง
              เฮลลิออท จำกัด จะไม่รับผิดชอบใดๆทั้งสิ้น <br />7.7 ผู้ใช้บริการ
              จะต้องรับผิดชอบในเนื้อหา และ/หรือสื่ออื่นใด
              ในบัญชีของผู้ใช้บริการเอง ในกรณีเกิดข้อโต้แย้ง/ข้อพิพาท กับบุคคล,
              กลุ่มบุคคล หรืออื่นๆ ทาง เฮลลิออท จำกัด จะไม่รับผิดชอบใดๆ ทั้งสิ้น
            </p>
            <p>
              ข้อ 8 การยกเว้นความรับผิด <br />8.1 เฮลลิออท จำกัด
              จะถูกยกเว้นความรับผิดชอบในกรณีที่ เฮลลิออท จำกัด
              ไม่สามารถให้บริการได้เนื่องจากเหตุสุดวิสัย<br />
              8.2 เฮลลิออท จำกัด
              จะไม่รับผิดชอบเกี่ยวกับการระงับหรือข้อบกพร่องในการใช้บริการเนื่องมาจากผลทางกฎหมายใดหรือการกระทำผิดกฎหมายของผู้ใช้บริการ
              <br />
              8.3 เฮลลิออท จำกัด
              มีสิทธิ์ยกเว้นความรับผิดในกรณีที่เกิดเหตุขัดข้องทางไฟฟ้าและโทรคมนาคม
              <br />8.4 เฮลลิออท จำกัด
              ยกเว้นความรับผิดของตนในกรณีที่การให้บริการหยุดไปเนื่องจากเหตุการณ์ที่หลีกเลี่ยงไม่ได้
              เช่น การแก้ไข เปลี่ยนแปลง การตรวจเช็ค อุปกรณ์จีพีเอส ล่วงหน้าแล้ว
              <br />
              8.5 เฮลลิออท จำกัด
              จะไม่รับผิดชอบในกรณีที่ผู้ใช้บริการไม่สามารถทำกำไรตามที่ผู้ใช้บริการคาดหมายไว้
              และความเสียหายจากการเลือกใช้บริการ
              หรือการใช้บริการของผู้ใช้บริการเอง <br />8.6 เฮลลิออท จำกัด
              จะไม่รับผิดชอบจากการ Login
              ล่าช้าและความเสียหายที่เกิดขึ้นจากระบบการเชื่อมต่อล่าช้าได้<br />
              8.7 เฮลลิออท จำกัด จะไม่รับผิดชอบเกี่ยวกับความน่าเชื่อถือ
              ความแม่นยำ ของคำประกาศเงื่อนไขการเป็นสมาชิก
              และเงื่อนไขการใช้บริการแอปพลิเคชันภายใต้ลิขสิทธิ์ของ เฮลลิออท
              จำกัด ข้อมูลและข้อเท็จจริงในบริการ <br />8.8 เฮลลิออท จำกัด
              ไม่มีหน้าที่จะเข้ามาดูแลเกี่ยวกับปัญหาระหว่าง ผู้ใช้บริการด้วยกัน
              เฮลลิออท จำกัด
              ไม่มีหน้าที่ที่ต้องรับผิดชอบเกี่ยวกับค่าเสียหายที่เกิดขึ้น
            </p>
            <p>
              ข้อ 9 สิทธิในทรัพย์สินทางปัญญา <br />เฮลลิออท จำกัด
              มีความเป็นเจ้าของหรือมีกรรมสิทธิ์ในทรัพย์สินทางปัญญาบนแอปพลิเคชัน
              Dinoculars แต่เพียงผู้เดียว ผลงานหรือข้อมูลทั้งหมดบนแอปพลิเคชันนี้
              ไม่ว่าจะเป็นในรูปแบบลิขสิทธิ์
              เครื่องหมายการค้าและสิทธิในทรัพย์สินทางปัญญากรรมสิทธิ์หรือสิทธิอื่นๆ
              ห้ามมิให้ทำซ้ำ โอน เปลี่ยนแปลง แก้ไข เผยแพร่ หรือในรูปแบบอื่นๆ
              ไม่ว่าทั้งหมดหรือแต่บางส่วน
              โดยไม่ได้รับอนุญาตเป็นลายลักษณ์อักษรจาก เฮลลิออท จำกัด
              อย่างเด็ดขาด ผู้ละเมิดจะถูกดำเนินคดีตามกฎหมายอย่างสูงสุด
            </p>
            ข้อ 10 กฎหมายที่ใช้บังคับ<br />
            การตีความและการประยุกต์ใช้ข้อตกลงและเงื่อนไขการให้บริการนี้ให้เป็นไปตามกฎหมายไทย
            ที่มีผลใช้บังคับ
          </div>
          <div class="term-version">เวอร์ชั่น {{ termsVersion }}</div>
        </div>
        <div class="footer">
          <div class="checkbox">
            <div>
              <a-checkbox v-model="cookies" />
              กรุณายอมรับเงื่อนไขการใช้งานข้อมูลส่วนบุคคล และการใช้งานคุกกี้
            </div>
            <div>
              <a-checkbox v-model="marketing" />
              ยินยอมให้ Fleet by Heliot เก็บรวมรวมข้อมูลสำหรับการทำโฆษณา
              เพื่อให้เราช่วยนำเสนอเนื้อหาบริการที่เหมาะสมที่สุด
            </div>
          </div>
          <div class="btn">
            <a-button
              id="accept"
              type="primary"
              size="large"
              @click="acceptPDPA"
            >
              ยอมรับ
            </a-button>
            <a-button id="cancel" size="large" @click="onClosePDPAModal">
              ยกเลิก
            </a-button>
          </div>
        </div>
      </div>
    </modal>

    <miniSurveyModal
      v-if="isShowMiniSurvey"
      :surveyContent="surveyContent"
      @onClose="onCloseMiniSurvey"
    />
    <!-- <onboardUserModal v-if="isShowOnboardUser" @onClose="onCloseOnboardUser" /> -->
    <!-- <surveySep2024 v-if="isShowSep2024Survey" @onClose="onCloseSep2024Survey" /> -->

    <a-layout v-if="!loading" class="map-layout">
      <a-drawer
        title="แจ้งเตือน"
        placement="right"
        :closable="true"
        :visible="showNotification"
        :maskClosable="true"
        :mask="true"
        @close="onCloseDrawer"
        :width="480"
      >
        <div v-if="notifications.length > 0">
          <notification
            v-for="notification in notifications"
            :key="`${notification.data.id}-${moment().valueOf()}`"
            v-bind:title="notification.data.message"
            v-bind:message="notification.data.message"
            v-bind:type="notification.data.type"
            v-bind:imei="notification.data.imei"
            v-bind:arrive="notification.arrive"
            v-bind:id="notification.id"
          />
          <a-button type="primary" size="small" @click="getMoreNoti">
            ดูเพิ่ม
          </a-button>
        </div>
      </a-drawer>
      <leftSidebar />
      <a-layout>
        <header
          :style="{
            position: 'absolute',
            background: 'none',
            height: '50px',
            width: '_00px',
            padding: '0',
            top: '0px',
            right: '50px',
            zIndex: '99',
          }"
        ></header>
        <a-layout-content :style="{ margin: '0px 0px 0' }">
          <div
            :style="{
              padding: '0px',
              background: '#fff',
              height: 'calc(100vh)',
              textAlign: 'left',
            }"
          >
            <slot />
          </div>
        </a-layout-content>
      </a-layout>
      <div class="notibar" v-show="permission == 'DENIED'">
        เพื่อให้สามารถใช้งานการแจ้งเตือน กรุณาอนุญาตสิทธิ์สำหรับบราวเซอร์
      </div>
      <!-- <div class="survey-btn">
        <a
          target="_blank"
          href="https://docs.google.com/forms/d/e/1FAIpQLSezW_Y1nuiGM4Olpv977n8LFivdDSTAyFHjmX5VW-hPbOYpzA/viewform"
        >
          ทำแบบสอบถามลุ้นของรางวัล</a
        >
      </div> -->
    </a-layout>
    <a-layout v-else class="preload-container">
      <preload />
    </a-layout>
  </a-layout>
</template>
<script>
import leftSidebar from '@/views/components/leftSidebarResponsive';
import preload from '@/views/components/preload';
import notification from '@/views/components/notification';
import { mapState, mapActions, mapGetters } from 'vuex';
import { getNotifications, getUnreadNotifications } from '@/lib/notification';
import { getVehicles } from '@/lib/vehicle';
import moment from '@/lib/time';
import { isMobile } from 'mobile-device-detect';
import { pushLastRead, saveSetting } from '@/lib/authen';
import miniSurveyModal from '@/views/components/modal/miniSurvey.vue';
// import onboardUserModal from '@/views/components/modal/onboardUser.vue';
// import surveySep2024 from '@/views/components/modal/surveySep2024.vue';
import AxiosService from '../../plugins/axios';
import endpoints from '../../config/config';

const { detect } = require('detect-browser');
const browser = detect();
export default {
  components: {
    leftSidebar,
    preload,
    notification,
    miniSurveyModal,
    // onboardUserModal,
    // surveySep2024,
  },
  data() {
    return {
      moment,
      cookies: true,
      marketing: false,
      termsVersion: '1.00',
      // isShowOnboardUser: false,
      isShowMiniSurvey: false,
      surveyContent: {},
      // isShowSep2024Survey: false,
      // surveyDate: moment().utcOffset('+07:00').startOf('day').unix(),
    };
  },

  async mounted() {
    //show notification modal landing once
    // if (
    //   typeof Storage !== "undefined" &&
    //   localStorage.getItem("show_cluster_sept_maintenance") == null
    // ) {
    //   this.$modal.show("maintenance-modal");
    //   await localStorage.setItem("show_cluster_sept_maintenance", "OK");
    // }

    const previousPath = this.$route.params.previousPath;
    if (
      previousPath === '/login' &&
      this.user.termsVersion !== this.termsVersion
    ) {
      this.$modal.show('pdpa-modal');
    }

    // Show debt modal
    if (previousPath === '/login' && this.user.hasDebt === true) {
      localStorage.setItem('show_debt_modal', 'true');
      this.$modal.show('debt-modal');
    } else {
      if (localStorage.getItem('show_debt_modal') === 'true') {
        this.$modal.show('debt-modal');
      } else {
        this.$modal.hide('debt-modal');
      }
    }

    /* checking for unfinished survey */
    this.fetchSurveys();

    /* show onboard user  */
    // const date = moment().utcOffset('+07:00').startOf('day').unix();
    // const endDate = moment([2024, 9, 30, 0, 0, 0]).utcOffset('+07:00').unix();

    // if (date <= endDate) {
    //   console.log('Onboard');

    //   if (localStorage.getItem('show_onboard_user_modal') != date) {
    //     console.log('Onboard true', localStorage.getItem('show_onboard_user_modal'));
    //     this.isShowOnboardUser = true;
    //     localStorage.setItem('show_onboard_user_modal', date);
    //   } else {
    //     console.log('Onboard false');
    //     this.isShowOnboardUser = false;
    //   }
    // }

    // const surveyEndDate = moment([2024, 9, 30, 0, 0, 0])
    //   .utcOffset('+07:00')
    //   .unix();

    // if (this.surveyDate <= surveyEndDate) {
    //   // Storage key is old date, compares with present date.
    //   if (
    //     localStorage.getItem('show_sep_2024_survey_modal') != this.surveyDate
    //   ) {
    //     // If local key < surveyDate, show modal
    //     this.isShowSep2024Survey = true;
    //   } else {
    //     // If local key = or >, set key with surveyDate value
    //     this.isShowSep2024Survey = false;
    //   }
    // }
    //show maintenance modal once
    // if (
    //   typeof Storage !== 'undefined' &&
    //   localStorage.getItem('maintenance-modal-migrate') == null
    // ) {
    //   this.$modal.show('maintenance-modal');
    //   await localStorage.setItem('maintenance-modal-migrate', 'OK');
    // }

    if (
      parseInt(moment.utc().valueOf() / 1_000) <=
      parseInt(moment('2024-10-31T23:59:59+07:00').valueOf() / 1_000)
    ) {
      if (
        typeof Storage !== 'undefined' &&
        localStorage.getItem('cloud-maintenance-migrate-alert') == null
      ) {
        this.$modal.show('cloud-maintenance-migrate-alert');
        await localStorage.setItem('cloud-maintenance-migrate-alert', 'OK');
      }
    }
    //show survey modal
    // if (
    //   typeof Storage !== 'undefined' &&
    //   localStorage.getItem('show_fleet_v1_survey') == null
    // ) {
    //   this.$modal.show('survey-modal');
    //   await localStorage.setItem('show_fleet_v1_survey', 'OK');
    // }

    this.setLastRead(moment().unix());
    if (this.vehicles.length <= 0) {
      await getVehicles(
        this.user.customer_groups.map((group) => {
          return group._id;
        })
      );
    }

    if (this.unreadCount <= 0) {
      await getUnreadNotifications(this.devicesArray);
    }
    // console.log("storage in Vue", localStorage.getItem("token"));
    if (
      browser.name != 'safari' &&
      !isMobile &&
      typeof this.setting.notiEnable != 'undefined' &&
      this.setting.notiEnable != false
    ) {
      this.$messaging.onMessage((message) => {
        // console.log(message);
        this.addOneUnread();
        getNotifications(this.devicesArray, 20, true);
        // payload.callback("overspeed", message);
        if (message.data)
          this.popNoti(
            message.data.title,
            message.data.body,
            message.data.type
          );
        // [END_EXCLUDE]
      });

      // if (
      //   typeof Storage !== "undefined" &&
      //   localStorage.getItem("token") == null
      // ) {
      // console.log("request permission ");
      this.requestPermission({
        messaging: this.$messaging,
        customerGroups: this.groupStr,
        userid: this.generateDeviceID(),
      });
      // }
    }
  },
  methods: {
    ...mapActions({
      requestPermission: 'fcm/requestPermission',
      resetState: 'fcm/resetState',
      toggleShowNotification: 'authen/toggleShowNotification',
      setShowNotification: 'authen/setShowNotification',
      setLastRead: 'fcm/setLastRead',
      setUnread: 'fcm/setUnread',
      addOneUnread: 'fcm/addOneUnread',
      setShowDebtModal: 'authen/setShowDebtModal',
      // ptoChecked:"vehicle/ptoChecked"
    }),
    popNoti(title, message, type) {
      let icon = <a-icon type="exclamation-circle" style="color: #513fe8" />;
      switch (type) {
        case 'OVERSPEED':
          icon = <a-icon type="icon-Iconmetro-meter" style="color: #513fe8" />;
          break;
        case 'THAI_MCR_SWIPE_IN':
          icon = <a-icon type="idcard" style="color: #513fe8" />;
          break;
        case 'THAI_MCR_SWIPE_OUT':
          icon = <a-icon type="idcard" style="color: #513fe8" />;
          break;
        case 'GEOFENCE_ENTER':
          icon = <a-icon type="login" style="color: #513fe8" />;
          break;
        case 'GEOFENCE_EXIT':
          icon = <a-icon type="logout" style="color: #513fe8" />;
          break;
        default:
          icon = <a-icon type="thunderbolt" style="color: #513fe8" />;
      }
      if (this.setting.notiEnable != false)
        this.$notification.open({
          message: title,
          description: message,
          icon: icon,
        });
    },
    generateDeviceID() {
      if (/Mobi|Android/i.test(navigator.userAgent)) {
        return `${this.user.id}-mobileweb`;
      } else return `${this.user.id}-desktop`;
    },
    onCloseDrawer() {
      getNotifications(this.devicesArray, 20, false);
      this.setShowNotification(false);
      pushLastRead();
      this.setUnread(0);
      this.setLastRead(moment().unix());
    },
    getMoreNoti() {
      getNotifications(this.devicesArray, 20, false);
    },
    async acceptPDPA() {
      this.setting['marketing'] = this.marketing;
      this.setting['cookies'] = this.cookies;
      await saveSetting({
        userID: this.user.id,
        setting: this.setting,
        termsVersion: this.termsVersion,
      }).finally(() => this.$modal.hide('pdpa-modal'));
    },
    onClosePDPAModal() {
      this.$modal.hide('pdpa-modal');
    },

    handleCloseDebtModal() {
      this.$modal.hide('debt-modal');
      localStorage.setItem('show_debt_modal', 'false');
    },

    // onCloseOnboardUser() {
    //   this.isShowOnboardUser = false;
    // },

    onCloseSep2024Survey() {
      this.isShowSep2024Survey = false;
      localStorage.setItem('show_sep_2024_survey_modal', this.surveyDate);
    },

    onCloseMiniSurvey() {
      this.isShowMiniSurvey = false;
    },
    async fetchSurveys() {
      await AxiosService.post(`${endpoints.strapiAPI}/surveys/unfinished`, {
        customer_id: this.user.id,
      })
        .then((res) => {
          const { data } = res.data;
          if (data && data.length > 0) {
            if (data[0].type === 'mini_survey') {
              this.surveyContent = data[0];
              this.isShowMiniSurvey = true;
            }
          }
          return;
        })
        .catch((err) => {
          console.log(err);
          return;
        });
    },
  },
  computed: mapState({
    ...mapGetters({
      vehicles: 'vehicle/vehicles',
      groupStr: 'authen/groupStr',
      devicesArray: 'vehicle/devicesArray',
    }),
    loading: (state) => state.loading.loading,
    permission: (state) => state.fcm.permission,
    user: (state) => state.authen.user,
    setting: (state) => state.authen.setting,
    showNotification: (state) => state.authen.showNotification,
    notifications: (state) => state.fcm.notifications,
    offset: (state) => state.fcm.offset,
    unreadCount: (state) => state.fcm.unreadCount,
    lastRead: (state) => state.fcm.lastRead,
    isMobileDevice: () => isMobile,
  }),
  watch: {
    async showNotification(showState) {
      if (showState) {
        // x
        await getNotifications(this.devicesArray, 20, true);
        pushLastRead();
        // this.setLastRead(moment().unix());
        this.setUnread(0);
      }
    },
  },
};
</script>
<style lang="scss">
$xs: 390px;
$sm: 576px;
$md: 767px;
$xl: 1200px;
$xxl: 1440px;

.pdpa-modal {
  border-radius: 15px !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  margin-right: -50% !important;
  transform: translate(-50%, -50%) !important;
  width: 60% !important;
  height: 80% !important;
  z-index: 10; 
  padding: 45px 40px;
  @media screen and (max-width: $md) {
    padding: 18px 20px;
    width: 90% !important;
    height: 80% !important;
  }
}
.pdpa-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;

  .title {
    br {
      display: none;
    }
    font-size: 24px;
    @media screen and (max-width: 375px) {
      br {
        display: inline;
      }
    }
  }
  .body {
    flex: 1;
    color: #717171;
    margin: 20px 0px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;    
    padding: 15px 35px 5px 35px;
    background-color: #f0f0f0;
    text-align: left;
    .context {
      flex: 1 0 0;
      overflow-y: scroll;
      overflow-x: hidden;
    }
    .term-version {
      margin-top: 5px;
    }
    @media screen and (max-width: $md) {
      padding: 10px 15px 5px 15px;
    }
    @media screen and (max-width: 375px) {
      padding: 10px 10px 5px 15px;
    }
  }
  .footer {    
    .checkbox {      
      text-align: left;
      @media screen and (min-width: $xxl) {
        padding-left: 15%;
      }
    }
    @media screen and (min-width: $sm) {
      font-size: 1.1rem;
    }
  }
}
.btn {
  padding-top: 15px;
  #accept {
    border-radius: 15px !important;
    font-size: 1.2rem;    
    display: float;
    margin-right: 5%;
    @media screen and (min-width: $sm) {
      width: 20%;
    }
  }
  #cancel {
    border-radius: 15px !important;
    font-size: 1.2rem;    
    display: float;
    margin-left: 5%;
    @media screen and (min-width: $sm) {
      width: 20%;
    }
  }
}

.feature-modal {
  width: 320px;
  height: 500px;
  padding: 40px 30px;
  img {
    width: 100%;
    margin: 20px 0px;
  }
  .message {
    margin-top: 10px;
    text-align: left;
    h2 {
      font-size: 2rem;
      font-weight: 300;
      margin-bottom: 0 !important;
      line-height: 1.8rem;

      margin-top: 5px;
    }
    h3 {
      margin-bottom: 0 !important;
      font-size: 1.4rem;
      font-weight: 300;
      line-height: 1.6rem;
      color: #888;
    }
    h4 {
      color: #aaa;
      margin-bottom: 0 !important;
      font-size: 1.3rem;
      line-height: 1.6rem;
      color: #513fe8;
      font-weight: 300;
    }
    .desc {
      font-size: 1.1rem;
      margin-top: 20px;
    }
    strong {
      color: rgb(245, 34, 62);
    }
  }
  .heliot-btn {
    background-color: #513fe8 !important;
    font-size: 1.2rem;
    font-weight: bold;
  }
}

.survey-modal {
  padding: 12px 0px;
  img {
    width: 70%;
    margin: 0px 0px;
  }

  .heliot-btn {
    background-color: #513fe8 !important;
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0 10px;
  }
  @media screen and (min-width: $md) {
    max-width: 70%;
    max-height: 95%;
    margin: auto auto;
  }
  @media screen and (min-width: 1800px) {
    img {
      width: 80%;
    }
    max-width: 70%;
    max-height: 100%;
    margin: auto auto;
  }
}

.notibar {
  background: #f5223e;
  height: 32px;
  color: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  z-index: 9999;
  line-height: 32px;
}
.survey-btn {
  background-color: #e921ab;
  height: 40px;
  padding: 7px 15px;
  position: absolute;
  left: calc(62vw);
  top: 0px;
  margin: 0 auto;
  color: #fff;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  a {
    color: #fff;
    font-size: 1.1rem;
  }
  @media screen and (max-width: $md) {
    position: fixed;
    right: 0px;
    bottom: 0px;
    left: auto;
    top: auto;
    max-height: 80%;
    margin: 0 0;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  @media screen and (max-width: 480px) {
    position: fixed;
    right: 0px;
    bottom: 0px;
    left: auto;
    top: auto;
    max-height: 80%;
    margin: 0 0;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  // transform: translateX(50%);
}

.debt-modal {
  border-radius: 16px !important;
  height: fit-content !important;
  width: 600px !important;
  inset: 30% 0 !important;
  margin: 0 auto !important;
  z-index: 1;

  /* For tablet */
  @media screen and (max-width: $md) {
    width: 65% !important;
    height: fit-content !important;
  }

  @media screen and (max-width: $sm) {
    width: 80% !important;
    height: fit-content !important;
  }

  /* For mobile */
  /* @media screen and (max-width: $xs) {
    width: 80% !important;
    height: 250px !important;
  } */

  .debt-modal-container {
    display: flex;
    position: relative;
    height: 100%;
    padding: 48px 156px 24px 156px;
    

    .debt-modal-close-btn {
      position: absolute;
      cursor: pointer;
      width: fit-content;
      right: 0;
      top: 0;
      margin-top: 24px;
      margin-right: 24px;

      @media screen and (max-width: $md) {
        height: 14px;
        width: 14px;
      }

      @media screen and (max-width: $sm) {
        height: 12px;
        width: 12px;
      }
    }

    .debt-modal-content {
      position: relative;
      height: max-content;

      .debt-modal-header-txt {
        color: #4838de;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        @media screen and (max-width: $md) {
          font-size: 20px;
        }

        @media screen and (max-width: $sm) {
          font-size: 18px;
        }
      }
      .debt-modal-detail-txt {
        color: #000;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 32px;

        @media screen and (max-width: $md) {
          margin-top: 24px;
          font-size: 14px;
        }

        @media screen and (max-width: $sm) {
          margin-top: 18px;
          font-size: 12px;
        }
      }
      .debt-modal-contact-staff-btn {
        border-radius: 16px;
        border: none;
        background: #4838de;

        max-width: 256px;
        max-height: 64px;
        padding: 12px 52px;

        cursor: pointer;

        &:hover {
          background: #3724e0;
        }

        a {
          text-decoration: none;
          color: #fff;
          text-align: center;
          font-size: 24px;
          font-weight: 500;
          font-style: normal;
        }

        @media screen and (max-width: $md) {
          padding: 12px 32px;
          margin-top: 12px;

          a {
            font-size: 16px;
          }
        }

        @media screen and (max-width: $sm) {
          margin-top: 16px;
        }

        @media screen and (max-width: $xs) {
          padding: 8px 16px;
          margin-top: 16px;

          a {
            font-size: 14px;
          }
        }
      }
      .debt-modal-bottom-txt {
        margin-top: 20px;
        color: #000;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;

        @media screen and (max-width: $md) {
          margin-top: 12px;
          font-size: 16px;
        }

        @media screen and (max-width: $sm) {
          margin-top: 12px;
          font-size: 12px;
        }
      }
    }

    /* For tablet */
    @media screen and (max-width: $md) {
      padding: 32px 80px 32px 80px;
    }

    @media screen and (max-width: $sm) {
      padding: 32px 64px 23px 64px;
    }

    /* For mobile */
    @media screen and (max-width: $xs) {
      padding: 32px 56px 23px 56px;
    }
  }
}
</style>
<style lang="scss" global>
.context::-webkit-scrollbar {
  width: 10px;
}

.context::-webkit-scrollbar-track {
  background: rgba(192, 192, 192, 0.5);
  opacity: 0;
  border-radius: 11px;
}

.context::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 15px;
  // box-shadow: 0px 5px 10px rgba(20, 30, 54, 0.8);
}
.ant-layout {
  background: #fff;
}

#components-layout-demo-responsive .logo {
  height: 32px;
  margin: 16px;
  color: #fff;
  font-size: 2rem;
}

// .ant-menu-item {
//   padding-left: 50px !important;
//   text-align: left;
//   svg.sli-svg {
//     width: 14px;
//     display: inline-block;
//     margin-right: 10px;
//     color: rgba(255, 255, 255, 0.65) !important;
//     display: inline-block;
//     vertical-align: middle;
//   }
// }
.map-layout {
  width: 100%;
}
/* .ant-layout-header {
  // height: 52px !important;
} */

.ant-breadcrumb {
  padding: 10px 20px !important;
  display: none;
}
.ant-layout-header {
  text-align: right;
  padding-right: 10px !important;
  line-height: 50px;
}
// .heliot-sidebar {
//   // flex: 0 0 80px !important;
//   // max-width: 80px !important;
//   // min-width: 80px !important;
//   // width: 80px !important;
//   top: 43px;
//   left: 0px;
//   width: 65px;
//   height: 1037px;
//   background: #ffffff 0% 0% no-repeat padding-box;
// }

.ant-menu-item {
  color: #000;
}
.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  color: rgba(255, 255, 255, 1) !important;
  background: none !important;
}

.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a {
  color: rgba(255, 255, 255, 1) !important;
  font-weight: 300;
}

// .ant-menu.ant-menu-dark .ant-menu-item-selected,
// .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
//   background-color: #2d3539 !important;
// }
.ant-menu-dark .ant-menu-item-selected {
  //background-color: #414344 !important;
  color: #fff !important;
  padding: 5px 2px;
}

.ant-layout.ant-layout-has-sider {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 100vh;
}
.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #888 !important;
  border-color: #888 !important;
  .ant-radio-button-wrapper {
    &:hover {
      position: relative;
      color: #414344 !important;
    }
  }
}
.ant-radio-button-wrapper:hover {
  position: relative;
  color: #00c246 !important;
}

.ant-radio-button-wrapper-checked {
  z-index: 1;
  color: #414344;
  background: #fff;
  border-color: #414344;
  box-shadow: none !important;
}
.ant-progress-status-success .ant-progress-text {
  color: rgba(0, 0, 0, 0.45) !important;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0px !important;
}

// .gm-style-iw {
//   .gm-ui-hover-effect {
//     display: none !important;
//   }
// }

.anticon-close-circle {
  color: red;
  &:hover {
    color: red;
  }
}

.ant-drawer-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
}
.ant-switch-checked {
  background-color: #00c246 !important;
}
</style>
