<template>
    <a-select
      :class="['select-input', 'input-box']"
      option-filter-prop="children"
      show-search
      showArrow
      :filter-option="filterOption"
      placeholder="เลือกประเภทการแจ้งเตือน"
      @change="onChangeEvent"
      :value="selectedEvent"
      :disabled="mode === 'edit' ? true : false"
    >
      <a-select-option
        v-for="eventVal in events"
        :key="eventVal.label"
        :value="eventVal.type"
      >
        {{ eventVal.label }}
      </a-select-option>
    </a-select>
  </template>
  
  <script>
  export default {
    name: 'vehicleSingleSelect',
    props: ['defaultValue', 'events', 'mode', 'selectedEventType'],
    emits: ['onSelect'],
  
    data() { 
      return {
        selectedEvent: undefined,
      };
    },
  
    watch: {
      defaultValue(value) {
        this.selectedEvent = value === '' ? undefined : value;
      },
    },
  
    mounted() {
      this.selectedEvent =
        this.defaultValue === '' ? undefined : this.defaultValue;
    },
  
    methods: {
      onChangeEvent(event) {
        this.selectedEvent = event;
        this.$emit('onSelect', this.selectedEvent);
      },
  
      filterOption(input, option) {
        return (
          option.componentOptions.children[0].text
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        );
      },
    },
  };
  </script>
  
  <style lang="scss">
  .select-input .ant-select-selection--single {
    height: 48px !important;
    &:hover {
      border-color: #523ee8;
    }
  
    .ant-select-selection__rendered {
      height: 48px !important;
    }
    .ant-select-selection-selected-value {
      padding-top: 8px;
    }
  }
  
  .input-box {
    height: 48px;
    width: 100%;
  
    &:hover {
      border-color: #523ee8 !important;
    }
  }
  </style>
  