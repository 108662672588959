<template>
  <a-drawer
    placement="bottom"
    :closable="true"
    :mask="false"
    :visible="selectedVehicle"
    :maskClosable="true"
    @close="onCloseDrawer"
    :height="190"
    wrapClassName="bottom-drawer"
    :destroyOnClose="true"
    :z-index="50"
  >
    <bottomCurrentDevice />
  </a-drawer>
</template>
<script>
import bottomCurrentDevice from "@/views/components/bottomCurrentDevice";
import { mapState, mapActions } from "vuex";
export default {
  components: { bottomCurrentDevice },
  // props: {
  //   selectedVehicle: { type: Object }
  // },
  data() {
    return {
      collapsed: true,
      bottomDrawer: true,
    };
  },
  computed: mapState({
    selectedVehicle: (state) => state.vehicle.showVehicleInfo,
  }),
  methods: {
    ...mapActions({
      showVehicleInfo: "vehicle/showVehicleBottomInfo",
      selectVehicle: "vehicle/selectVehicle",
    }),
    onCloseDrawer() {
      this.showVehicleInfo(false);
      this.selectVehicle({});
    },
  },
};
</script>
<style lang="scss">
$md: 768px;
$sd: 480px;
.bottom-drawer {
  background: #ffffff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  .ant-drawer-content-wrapper {
    

    right: 0px;
    margin-right: 0px !important;

    border-top-left-radius: 7px;
    .ant-drawer-content {
      border-top-left-radius: 7px;
      border-top-right-radius: 00px;
      background: #ffffff;
      @media screen and (min-width: $md) {
        .ant-drawer-body {
          padding: 10px 24px;
        }
      }
      @media screen and (max-width: $sd) {
        .ant-drawer-body {
          padding: 15px 5px;
        }
      }
    }
    @media screen and (max-width: $md) {
      width: 100% !important;
    }
    @media screen and (min-width: $md) {
      width: 700px !important;
    }
  }
  .ant-drawer-close {
    color: #bbb;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    // color: #fff;
  }
  h1 {
    font-weight: 700 !important;
  }
}
.device-drawer {
  margin-left: 80px;
}
</style>
