<template>
  <defaultLayout>
    <div class="maintenance-container">
      <div class="title">การแจ้งเตือน</div>

      <!-- Menu tabs -->
      <div class="navigation-container">
        <div
          @click="handleTabClick('event')"
          :class="['navigation-button', eventTabActived && 'active']"
        >
          Event
        </div>
        <div
          @click="handleTabClick('maintenance')"
          :class="['navigation-button', maintenanceTabActived && 'active']"
        >
          Maintenance
        </div>
      </div>

      <div class="header-container">
        <a-input-search
          placeholder="ค้นหา"
          class="search-bar"
          @search="onSearch"
          v-model="searchQuery"
        />
        <a-button @click="enableCreateModal" class="add-noti-button">
          เพิ่มแจ้งเตือน
        </a-button>
      </div>

      <!-- Event table -->
      <div v-if="eventTabActived" class="table-data">
        <a-table
          class="noti-table"
          :columns="eventColumns"
          :data-source="filteredEventData"
          :row-key="(record) => record.id"
          :customRow="
            (record) => {
              return {
                on: {
                  click: () => handleRowClick(record, 'edit'),
                },
              };
            }
          "
        >
          <span slot="plate_number" slot-scope="record" style="color: black">
            <div v-if="record.data_source === 'geo_activity_alert'">
              <!-- Check length to formatted plate number text -->
              <div v-if="plateNumberFormat(record.vehicle).length > 1">
                {{ plateNumberFormat(record.vehicle)[0] }}, ...
                <a-dropdown :trigger="['click']">
                  <a @click.stop.prevent style="position: relative">
                    <img
                      src="@/assets/icons/circle_info.svg"
                      alt="circle_info"
                      class="table-vehicle_list-btn"
                    />
                  </a>
                  <a-menu slot="overlay" id="table-vehicle_info-dropdown">
                    <a-menu-item
                      v-for="plateNumber in plateNumberFormat(record.vehicle)"
                      :key="plateNumber"
                    >
                      {{ plateNumber }}
                      <div
                        class="divider"
                        style="background-color: #eaeaea"
                      ></div>
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
              </div>
              <div v-else>
                {{ plateNumberFormat(record.vehicle)[0] }}
              </div>
            </div>
            <div v-else>
              {{ record.vehicle.plate_number }}
            </div>
          </span>
          <span slot="noti_name" slot-scope="record">
            {{ record.name ? record.name : '-' }}
          </span>
          <span slot="event_condition" slot-scope="record">
            {{ conditionFormat(record?.type, record?.notify_type, record.id) }}
          </span>
          <span slot="geofence" slot-scope="record">
            <div v-if="record.all_geofences_notify">ทั้งหมด</div>
            <div
              v-else-if="record.geofence && !record.all_geofences_notify"
              style="text-overflow: ellipsis; overflow: hidden"
            >
              {{ record.geofence.name }}
            </div>
          </span>
          <span slot="status" slot-scope="record">
            <a-switch
              v-if="record.data_source === 'maintenance'"
              :default-checked="record.status === 'Disabled' ? false : true"
              @change="onEventStatusChange"
              @click.native.stop="
                onClickEventStatus(record.id, record.data_source)
              "
            />
          </span>
          <span slot="delete" slot-scope="record">
            <a @click.stop.prevent="showDelModal(record)">
              <icon-font type="icon-delete" class="table-del-btn" />
            </a>
          </span>
        </a-table>
      </div>

      <!-- Maintenance table -->
      <div v-if="maintenanceTabActived" class="table-data">
        <a-table
          class="noti-table"
          :columns="maintenanceColumns"
          :data-source="filteredMaintenances"
          :row-key="(record) => record.id"
          :customRow="
            (record) => {
              return {
                on: {
                  click: () => handleRowClick(record, 'edit'),
                },
              };
            }
          "
        >
          <span slot="plate_number" slot-scope="record" style="color: black">
            {{ record.vehicle.plate_number }}
          </span>
          <span slot="noti_name" slot-scope="record">
            {{ record.name }}
          </span>
          <span slot="next" slot-scope="record">
            <!-- {{
              (record.vehicle.last_odo_meter + record.remaining_value) / 1000 || '-'
            }} -->
            {{
              notifyTFormat(
                record.type,
                (record.vehicle.last_odo_meter + record.remaining_value) / 1000,
                record.date_type_schedule
              ) || '-'
            }}
          </span>
          <span slot="last_notify" slot-scope="record">
            {{ record.last_notify ? tFormat(record.last_notify) : '-' }}
          </span>
          <span slot="repeat_task_after" slot-scope="record">
            {{
              record.repeat_task_after
                ? notifyTFormat(record.type, record.repeat_task_after)
                : '-'
            }}
          </span>
          <span slot="remaining" slot-scope="record">
            {{
              record.remaining_value
                ? notifyTFormat(record.type, record.remaining_value)
                : '-'
            }}
          </span>
          <span slot="delete" slot-scope="record">
            <a @click.stop.prevent="showDelModal(record)">
              <icon-font type="icon-delete" class="table-del-btn" />
            </a>
          </span>
        </a-table>
      </div>      
      <maintenanceForm
        :visible="showFormDrawer"
        :vehicles="vehicles"
        :selectedGroup="selectedGroup"
        :notiData="recordData"
        :notiType="recType"
        :managementMode="mode"
        :isMainEventTabActived="eventTabActived"
        @onSubmit="onSaveForm"
        @onCancel="() => (this.showFormDrawer = false)"
      />

      <!-- Delete modal -->
      <a-modal
        :afterClose="resetDeleteModalState"
        :visible="delModalVisible"
        :centered="true"
        @ok="handleEventDelOk"
        @cancel="handleEventDelCancel"
        :footer="null"
        :width="400"
        :height="240"
      >
        <!-- Never click delete yet -->
        <div class="modal-icon-container">
          <img
            v-if="!confirmDelModalVisible"
            src="@/assets/icons/exclamation.svg"
            alt="Exclamation_icon"
          />
          <img v-else src="@/assets/icons/checkmark.svg" alt="Checkmark_icon" />
        </div>
        <p class="modal-content">
          {{
            !confirmDelModalVisible
              ? 'คุณกำลังลบการแจ้งเตือน'
              : 'ลบการแจ้งเตือนสำเร็จ'
          }}
        </p>
        <div class="modal-btn-container">
          <div v-if="!confirmDelModalVisible">
            <button
              @click="handleEventDelCancel"
              class="modal-btn modal-cancel_btn"
            >
              ยกเลิก
            </button>
            <button
              @click="handleEventDelOk"
              type="submit"
              class="modal-btn modal-confirm_btn"
            >
              ยืนยัน
            </button>
          </div>
          <div v-else>
            <button
              @click="handleConfirmDelClose"
              class="modal-btn modal-cancel_btn"
            >
              ปิดหน้าต่าง
            </button>
          </div>
        </div>
      </a-modal>
    </div>
  </defaultLayout>
</template>

<script>
// @ is an alias to /src
import defaultLayout from '@/views/layouts/defaultLayout.vue';
import maintenanceForm from './components/maintenance/maintenanceForm.vue';
import { mapActions, mapState, mapGetters } from 'vuex';
import moment from '@/lib/time';
// import cronParser from 'cron-parser';
// import cronstrue from 'cronstrue';
import { IconFont } from '@/config/config';
import { getVehicles } from '../lib/vehicle';

const eventColumns = [
  {
    key: 'plate_number',
    title: 'ทะเบียนรถ',
    scopedSlots: { customRender: 'plate_number' },
    width: '6%',
    align: 'center',
  },
  {
    key: 'noti_name',
    title: 'ชื่อแจ้งเตือน',
    scopedSlots: { customRender: 'noti_name' },
    width: '7%',
    align: 'center',
    ellipsis: true,
  },
  {
    key: 'event_condition',
    title: 'เงื่อนไข',
    scopedSlots: { customRender: 'event_condition' },
    width: '5%',
    align: 'center',
    ellipsis: true,
  },
  {
    key: 'geofence',
    title: 'สถานี',
    scopedSlots: { customRender: 'geofence' },
    width: '4%',
    align: 'center',
    ellipsis: true,
  },
  {
    key: 'status',
    title: 'สถานะ',
    scopedSlots: { customRender: 'status' },
    width: '5%',
    align: 'center',
  },
  {
    key: 'delete',
    title: '',
    scopedSlots: { customRender: 'delete' },
    width: '2%',
    align: 'center',
  },
];

const maintenanceColumns = [
  {
    key: 'plate_number',
    title: 'ทะเบียนรถ',
    scopedSlots: { customRender: 'plate_number' },
    width: '5%',
    align: 'center',
  },
  {
    key: 'noti_name',
    title: 'ชื่อแจ้งเตือน',
    scopedSlots: { customRender: 'noti_name' },
    width: '7%',
    align: 'center',
    ellipsis: true,
  },
  {
    key: 'next',
    title: 'ครั้งถัดไป',
    scopedSlots: { customRender: 'next' },
    width: '4%',
    align: 'center',
    ellipsis: true,
  },
  {
    key: 'last_notify',
    title: 'ครั้งล่าสุด',
    scopedSlots: { customRender: 'last_notify' },
    width: '3.5%',
    align: 'center',
    ellipsis: true,
  },
  {
    key: 'repeat_task_after',
    title: 'แจ้งเตือนซ้ำ',
    scopedSlots: { customRender: 'repeat_task_after' },
    width: '5%',
    align: 'center',
  },
  {
    key: 'remaining',
    title: 'Remaining',
    scopedSlots: { customRender: 'remaining' },
    width: '5%',
    align: 'center',
  },
  {
    key: 'delete',
    title: '',
    scopedSlots: { customRender: 'delete' },
    width: '2%',
    align: 'center',
  },
];

export default {
  name: 'Maintenance',
  components: {
    defaultLayout,
    IconFont,
    maintenanceForm,
  },

  data() {
    return {
      eventColumns,
      maintenanceColumns,
      delModalVisible: false,
      confirmDelModalVisible: false,
      recordKey: '',
      eventStatus: false,
      showFormDrawer: false,
      eventRecordID: '',
      selectedVehicle: [],
      vehicleList: [],
      searchQuery: '',
      recordItem: [],
      recordData: {},
      recType: '',
      mode: '',

      // Main Tab
      eventTabActived: true,
      maintenanceTabActived: false,
    };
  },
  watch: {
    selectedGroup() {
      this.getAllMaintenances(this.selectedGroup.id);
      this.getAllArrivalGeoAlerts(this.selectedGroup.id);
      this.getAllGeoActAlerts(this.selectedGroup.id);
    },
  },

  async mounted() {
    if (this.vehicles.length <= 0) {
      await getVehicles(
        this.user.customer_groups.map((group) => {
          return group._id;
        })
      );
    }
    this.getAllMaintenances(this.selectedGroup.id);
    this.getAllArrivalGeoAlerts(this.selectedGroup.id);
    this.getAllGeofences(this.selectedGroup.id);
    this.getAllGeoActAlerts(this.selectedGroup.id);
  },

  computed: {
    ...mapState({
      selectedGroup: (state) => state.authen.selectedGroup,
      maintenances: (state) => state.maintenance.maintenances,
      arrivalGeoAlerts: (state) => state.arrivalGeoAlerts.arrivalGeoAlertsData,
      vehicleGroups: (state) => state.vehicle.vehicleGroups,
      user: (state) => state.authen.user,
      geoActivityAlertsData: (state) =>
        state.geoActivityAlert.geoActivityAlertsData,
    }),
    ...mapGetters({
      vehicles: 'vehicle/vehicles',
      vehicleByIMEI: 'vehicle/vehicleByIMEI',
    }),

    computedAllData() {
      if (
        this.maintenances &&
        this.arrivalGeoAlerts &&
        this.geoActivityAlertsData
      ) {
        // Add data source type from where they are in each array
        return [
          ...this.maintenances.map((item) => ({
            ...item,
            data_source: 'maintenance',
          })),
          ...this.arrivalGeoAlerts.map((item) => ({
            ...item,
            data_source: 'arrival_geo_alert',
          })),
          ...this.geoActivityAlertsData.map((item) => ({
            ...item,
            data_source: 'geo_activity_alert',
          })),
        ];
      } else {
        return console.warn("There's no data in array");
      }
    },

    computedEventData() {
      return this.computedAllData.filter((item) => {
        return (
          this.filterByType(item, 'event') ||
          this.filterByDataSource(item, 'event')
        );
      });
    },

    filteredEventData() {
      return this.computedEventData.filter((filtered_item) => {
        const name = filtered_item.name || '';

        if (Array.isArray(filtered_item.vehicle)) {
          const vehiclePlateNumberArray = filtered_item.vehicle?.some((item) =>
            item.plate_number
              .toLowerCase()
              .includes(this.searchQuery?.toLowerCase())
          );
          return (
            vehiclePlateNumberArray ||
            name.toLowerCase().includes(this.searchQuery?.toLowerCase())
          );
        } else {
          const vehiclePlateNumber = filtered_item.vehicle?.plate_number || '';
          return (
            vehiclePlateNumber
              .toLowerCase()
              .includes(this.searchQuery?.toLowerCase()) ||
            name.toLowerCase().includes(this.searchQuery?.toLowerCase())
          );
        }
      });
    },

    computedMaintenanceData() {
      return this.computedAllData.filter((item) => {
        return (
          this.filterByType(item, 'maintenance') ||
          this.filterByDataSource(item, 'maintenance')
        );
      });
    },

    filteredMaintenances() {
      return this.computedMaintenanceData.filter((filtered_item) => {
        const vehiclePlateNumber = filtered_item.vehicle?.plate_number || '';
        const name = filtered_item.name || '';

        return (
          vehiclePlateNumber
            .toLowerCase()
            .includes(this.searchQuery?.toLowerCase()) ||
          name.toLowerCase().includes(this.searchQuery?.toLowerCase())
        );
      });
    },
  },
  methods: {
    ...mapActions({
      // Maintenances
      getAllMaintenances: 'maintenance/getAllMaintenances',
      createMaintenanceItem: 'maintenance/createMaintenanceItem',
      updateMaintenanceItemStatus: 'maintenance/updateMaintenanceItemStatus',
      updateMaintenanceData: 'maintenance/updateMaintenanceData',
      deleteEventMaintenanceItem: 'maintenance/deleteEventMaintenanceItem',

      // Arrival Geo Alerts
      getAllArrivalGeoAlerts: 'arrivalGeoAlerts/getAllArrivalGeoAlerts',
      createArrivalGeofenceAlertsItem:
        'arrivalGeoAlerts/createArrivalGeofenceAlertsItem',
      updateArrivalGeoAlertItem: 'arrivalGeoAlerts/updateArrivalGeoAlertItem',
      deleteArrivalGeoAlertItem: 'arrivalGeoAlerts/deleteArrivalGeoAlertItem',

      // Geofences
      getAllGeofences: 'geofence/getAllGeofences',

      // Geo activity alerts
      getAllGeoActAlerts: 'geoActivityAlert/getAllGeoActAlerts',
      createGeoActAlertsItem: 'geoActivityAlert/createGeoActAlertsItem',
      updateGeoActAlertsItem: 'geoActivityAlert/updateGeoActAlertsItem',
      deleteGeoActAlertsItem: 'geoActivityAlert/deleteGeoActAlertsItem',
    }),

    filterOption(input, option) {
      // return option.value.toLowerCase().indexOf(input.toUpperCase()) >= 0;
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },

    filterByType(item, data_type) {
      if (data_type === 'event') {
        return (
          item.type &&
          (item.type.includes('NO_UPDATE') ||
            item.type.includes('TEMPERATURE') ||
            item.type.includes('OVERSPEED') ||
            item.type.includes('NO_SWIPE_CARD_5MINS') ||
            item.type.includes('PTO_ON_OFF')
          )
        );
      } else {
        return (
          item.type &&
          (item.type.includes('Mileage') ||
            item.type.includes('PTO1') ||
            item.type.includes('PTO2') ||
            item.type.includes('PTO3') ||
            item.type.includes('Date'))
        );
      }
    },

    filterByDataSource(item, data_type) {
      if (data_type === 'event') {
        return (
          item.data_source === 'arrival_geo_alert' ||
          item.data_source === 'geo_activity_alert' ||
          !item.data_source
        );
      } else {
        return item.data_source === 'maintenances';
      }
    },

    plateNumberFormat(vehicle_data) {
      const found_plate_no = vehicle_data.map((item) => item.plate_number);
      return found_plate_no;
    },

    conditionFormat(mt_type, geo_type, row_id) {
      const mt_row_index = this.maintenances.findIndex(
        (item) => item.id === row_id
      );

      // create an object called messages that maps each mt_type to its corresponding message.
      const messages = {
        TEMPERATURE1: `อุณหภูมิ ${this.maintenances[mt_row_index]?.vehicle?.sensor1_temp_min} ถึง ${this.maintenances[mt_row_index]?.vehicle?.sensor1_temp_max}`,
        TEMPERATURE2: `อุณหภูมิ ${this.maintenances[mt_row_index]?.vehicle?.sensor2_temp_min} ถึง ${this.maintenances[mt_row_index]?.vehicle?.sensor2_temp_max}`,
        fixed30mins: `ก่อนเข้าสถานี 30 นาที`,
        OVERSPEED: `ความเร็วมากกว่า ${this.maintenances[mt_row_index]?.vehicle?.overspeed_limit} กม./ชม.`,
        NO_UPDATE: 'รถไม่อัพเดทเป็นเวลา 20 นาที',
        PTO1: 'ส่งรายงานเปิดใช้ PTO1',
        PTO2: 'ส่งรายงานเปิดใช้ PTO2',
        PTO3: 'ส่งรายงานเปิดใช้ PTO3',
        GEOFENCE_ENTER: 'เข้าสถานี',
        GEOFENCE_EXIT: 'ออกสถานี',
        NO_SWIPE_CARD_5MINS: 'ไม่รูดบัตร เกิน 5 นาที',
        PTO_ON_OFF: 'เปิด/ปิด PTO'
      };

      // use the mt_type as the key to look up the message in the messages object.
      return messages[mt_type || geo_type] || '';
    },

    tFormat(input) {
      return moment.utc(input).local().format('DD MMM YYYY HH:mm');
    },

    notifyTFormat(type, input, date_type_schedule) {
      if (type === 'Mileage') {
        return !Number.isNaN(input) ? `${input} กม.` : '-';
      }

      if (type.includes('PTO1') || type.includes('PTO2') || type.includes('PTO3')) {
        return !Number.isNaN(input) ? `${input} ชม.` : '-';
      }

      if (type === 'Date') {
        return this.tFormat(date_type_schedule);
      }
    },

    // convertCronToDateTime(cron_date, notify_year) {
    //   try {
    //     const interval = cronParser.parseExpression(cron_date);
    //     const dayMonth = interval.fields.dayOfMonth[0];
    //     const hour = interval.fields.hour[0];
    //     const minute = interval.fields.minute[0];
    //     const month = interval.fields.month[0];
    //     const customFormatDate = `${notify_year}-${month}-${dayMonth} ${hour}:${minute}.000+07:00`;
    //     return moment
    //       .utc(customFormatDate)
    //       .locale('th')
    //       .format('DD MMM YYYY HH:mm');
    //   } catch (error) {
    //     return 'Invalid cron expression';
    //   }
    // },

    // cronFormat(input) {
    //   return cronstrue.toString(input, { verbose: true });
    // },

    ///// Min Tab Methods ////////
    handleTabClick(tab_name) {
      this.eventTabActived = tab_name === 'event';
      this.maintenanceTabActived = tab_name === 'maintenance';
    },

    ///// Drawer methods //////////
    enableCreateModal() {
      this.showFormDrawer = true;
      this.recordData = null;
      this.mode = 'create';
    },

    handleRowClick(rec_data, edit_enabled) {
      this.showFormDrawer = true;

      if (rec_data?.type?.includes('TEMPERATURE')) {
        this.recType = 'TEMPERATURE';
      } else if (rec_data?.type === 'PTO1' || rec_data?.type === 'PTO2' || rec_data?.type === 'PTO3') {
        this.recType = 'PTO';
      } else {
        this.recType = rec_data?.type || rec_data?.notify_type;
      }

      this.recordData = rec_data;
      this.mode = edit_enabled;
    },

    onCancelDrawer() {
      this.showFormDrawer = false;
      this.recType = '';
      this.recordData = null;
      this.mode = '';
    },

    ////////////////////////////////

    ////////// Search method ///////////////

    onSearch(search_value) {
      this.searchQuery = search_value;
    },

    ////////////////////////////////////

    // For delete event //

    showDelModal(record) {
      this.delModalVisible = true;
      this.recordItem = [record.id, record.data_source];
    },

    async handleEventDelOk() {
      if (this.recordItem[1] === 'maintenance') {
        const indexToDelete = this.maintenances.findIndex(
          (item) => item.id === this.recordItem[0]
        );
        const delItemData = [this.recordItem[0], indexToDelete];

        try {
          await this.deleteEventMaintenanceItem(delItemData);
          if (
            this.maintenances.findIndex(
              (item) => item.id === this.maintenances.id
            ) === -1
          ) {
            this.delModalVisibility();
          }
        } catch (error) {
          console.error("Can't delete maintenance item", error);
        }
      } else if (this.recordItem[1] === 'arrival_geo_alert') {
        const indexToDelete = this.arrivalGeoAlerts.findIndex(
          (item) => item.id === this.recordItem[0]
        );
        const delItemData = [this.recordItem[0], indexToDelete];

        try {
          await this.deleteArrivalGeoAlertItem(delItemData);
          if (
            this.arrivalGeoAlerts.findIndex(
              (item) => item.id === this.arrivalGeoAlerts.id
            ) === -1
          ) {
            this.delModalVisibility();
          }
        } catch (error) {
          console.error("Can't delete arrival geo alerts item", error);
        }
      } else if (this.recordItem[1] === 'geo_activity_alert') {
        const indexToDelete = this.geoActivityAlertsData.findIndex(
          (item) => item.id === this.recordItem[0]
        );
        const delItemData = [this.recordItem[0], indexToDelete];

        try {
          await this.deleteGeoActAlertsItem(delItemData);
          if (
            this.geoActivityAlertsData.findIndex((item) => item.id === this) ===
            -1
          ) {
            this.delModalVisibility();
          }
        } catch (error) {
          console.error("Can't delete geo activity alert item", error);
        }
      }
    },

    delModalVisibility() {
      this.confirmDelModalVisible = true;
      this.recordItem = [];
    },

    handleEventDelCancel() {
      this.delModalVisible = false;
    },

    handleConfirmDelClose() {
      this.handleEventDelCancel();
    },

    resetDeleteModalState() {
      this.confirmDelModalVisible = false;
      this.delModalVisible = false;
    },

    // For update event status //
    onEventStatusChange(status) {
      this.eventStatus = status;
    },

    async onClickEventStatus(record_id, record_source) {
      this.eventRecordID = record_id;
      await this.updateEventStatus(record_source);
    },

    async updateEventStatus(rec_source) {
      try {
        if (rec_source === 'maintenance') {
          this.eventStatus = this.eventStatus ? 'Normal' : 'Disabled';
          const updatedEventStatus = [this.eventRecordID, this.eventStatus];
          await this.updateMaintenanceItemStatus(updatedEventStatus);
        }
      } catch (error) {
        console.error('Updating error from maintenance.vue => ', error);
      }
    },

    // For NO_UPDATE time unit
    padZero(value) {
      return value.toString().padStart(2, '0');
    },

    async onSaveForm(data, data_id, mode, source, table_type) {
      // console.log("OnSaveForm:",mode, source, table_type, data_id, data);
      const data_payload = [data_id, data];
      if (mode === 'create') {
        if (table_type === 'arrival_geo_alert') {
          await this.createArrivalGeofenceAlertsItem(data_payload[1]);
        } else if (table_type === 'maintenance') {
          await this.createMaintenanceItem(data_payload[1]);
        } else if (table_type === 'geo_activity_alert') {
          await this.createGeoActAlertsItem(data_payload[1]);
        }
      } else {
        if (source === 'arrival_geo_alert') {
          await this.updateArrivalGeoAlertItem(data_payload);
        } else if (source === 'maintenance') {
          await this.updateMaintenanceData(data_payload);
        } else if (source === 'geo_activity_alert') {
          await this.updateGeoActAlertsItem(data_payload);
        }
      }
    },
  },
};
</script>

<style lang="scss">
.noti-table tbody tr:hover {
  cursor: pointer;
}

/* //////////// Ant Drawer ///////////////// */
#notification-add-drawer {
  .ant-drawer-header {
    position: relative !important;
    padding: 64px 80px 32px 64px !important;
    color: rgba(0, 0, 0, 0.65) !important;
    border-bottom: none !important;
  }

  .ant-drawer-header .ant-drawer-title {
    margin: 0 !important;
    color: rgba(0, 0, 0, 0.85) !important;
    font-weight: 500 !important;
    font-style: normal !important;
    font-size: 24px !important;
    line-height: normal !important;
  }

  .ant-drawer-body {
    padding: 0 64px 24px 64px;
  }
}
/* //////////////////////////////////////// */

/* //////////// Ant tab ///////////// */
#notification-add-tab,
#notification-main-tab {
  .ant-tabs-nav .ant-tabs-tab {
    color: #b3b3b3;
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: normal !important;
    text-align: center !important;
    margin: 0 0 0 0 !important;
  }

  .ant-tabs-bar {
    margin: 0 0 0 0 !important;
  }

  .ant-tabs-ink-bar {
    background-color: #362ba2 !important;
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    text-align: center !important;
    color: #362ba2 !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: normal !important;
    margin: 0 0 0 0 !important;
  }
}
/* //////////////////////////////// */
#parameter_event_add,
#vehicle_event_add {
  .ant-select-selection--single {
    position: relative;
    height: 48px;
    width: 100%;
    cursor: pointer;
    padding: 8px 12px;
    border: 1px solid #eaeaea;
    font-size: 14px;
    border-radius: 6px;

    .ant-select-selection__rendered {
      margin-left: 0px;
    }
  }
}

#noti_sending_checkbox {
  .ant-checkbox-wrapper {
    align-items: center;
    width: 30%;

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #523ee8;
      border-color: #523ee8;
      border-radius: 4px;
      height: 16px;
      width: 16px;
    }

    span {
      color: #515151;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px; /* 21px */
    }
  }
}
</style>

<style lang="scss" scoped>
$md: 768px;

.search-bar .ant-input {
  height: 48px !important;
  border: 1px solid #eaeaea !important;
  border-radius: 6px !important;
  padding: 8px 12px !important;
  display: flex !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
}

.search-bar .ant-input-search-icon {
  font-size: 24px;
}

.ant-table-thead > tr > th .ant-table-header-column {
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
}

.ant-table {
  font-size: 16px !important;
}

/* Components (buttons, icon, switch, and etc...) */
.ant-switch {
  background-color: #ffff !important;
  border: 1px solid rgba(179, 179, 179, 1) !important;
  width: 32px !important;
  height: 24px !important;
}

.ant-switch::after {
  background-color: #b3b3b3 !important;
  top: 3px !important;
  left: 3px !important;
  height: 16px !important;
  width: 16px !important;
}

.ant-switch-checked {
  border: 1px solid #00c246 !important;
}

.ant-switch-checked::after {
  background-color: #00c246 !important;
  left: 95% !important;
  margin-left: -1px !important;
  transform: translateX(-100%) !important;
  /* right: 0 !important; */
}

.ant-form-item {
  padding: 8px 12px 9px 12px !important;
}

/* ////////////// Custom Classes ///////////////// */
.maintenance-container {
  color: #000000;
  background-color: #fff !important;
  font-size: 14px;
  padding: 3.75rem;
  // height: 100%;
}
.title {
  font-size: 24px;
}
.navigation-container {
  margin-top: 34px;
  font-size: 18px;
  display: inline-block;
  .navigation-button {
    height: 30px;
    padding: 0 25px;
    display: inline-block;
    text-align: center;
    color: #b5b5b5;
    border-bottom: 1px solid #b5b5b5;
    cursor: pointer;

    &.active {
      color: #362ba2;
      border-bottom: 2px solid #362ba2;
    }
  }
}

.header-container {
  margin: 48px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-bar {
  width: 40%;
}

.add-noti-button {
  border-radius: 36px;
  background: #00c246;
  color: #fff;
  font-size: 16px;
  height: 44px;
  width: 120px;
}

.table-del-btn {
  color: rgba(179, 179, 179, 1);
  font-size: 20px;

  :hover {
    color: red;
  }
}

.modal-icon-container {
  display: flex;
  justify-content: center;
}

.modal-content {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  align-items: center;
  margin-top: 20px;
}

.modal-btn-container {
  text-align: center;
  margin-top: 44px;

  .modal-btn {
    border-radius: 6px;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    padding: 16px 32px;
    height: auto;
    width: auto;
    cursor: pointer;
  }

  .modal-cancel_btn {
    border: 1px solid #e4e4e4;
    background-color: white;
    color: #515151;
    margin-right: 16px;
  }

  .modal-cancel_btn:hover {
    color: white;
    background-color: #e4e4e4;
  }

  .modal-confirm_btn {
    border: none;
    background-color: #e821aa;
    color: white;
    margin-left: 16px;
  }

  .modal-confirm_btn:hover {
    background-color: #af167f;
  }
}

.noti_manage-btn-layout {
  margin: 40px 32px 0px 0px;
  width: auto;
  height: 48px;
  padding: 0 32px;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  border-radius: 6px;
  cursor: pointer;
}
.save-add_noti-btn {
  background-color: #523ee8;
  color: #fff;
  border: none;
}
.save-add_noti-btn:hover {
  background-color: #362ba2;
}
.cancel-add_noti-btn {
  background-color: #fff;
  color: #515151;
  border: 1px solid #e4e4e4;
}
.cancel-add_noti-btn:hover {
  background-color: #eae7e7;
}

/* ////////// Noti Drawer ////////////// */
.noti-input-layout {
  width: 100%;
  height: fit-content;
}

.row-drawer-layout {
  padding: 16px 0px;
}

.add_noti-label {
  color: #515151;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0;
  margin: 14px 0;
}

.noti-type-btn-group {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 16px;
  margin-top: 2px;

  button {
    width: auto;
    height: 48px;
    padding: 8px 24px;
    color: #515151;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    border: 1px solid #f5f5f5;
    background-color: #fff;
    cursor: pointer;
  }

  button:hover {
    background-color: #523ee8;
    color: white;
  }

  .noti-type-btn-active {
    background-color: #523ee8;
    color: white;
  }
}

.noti-input-box {
  width: 100%;
  height: 48px;
  padding: 8px 12px;
  font-size: 14px;
  color: #515151;

  border-radius: 6px;
  border: 1px solid #eaeaea;
}

.noti-input-box:hover {
  border-color: #523ee8 !important;
}
.noti-sending-layout {
  margin-top: 16px;

  .ant-checkbox-wrapper {
    width: 15% !important;
  }

  .noti-sending-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 8px;

    .noti-sending-text-input {
      width: 100%;
      height: 48px;
      padding: 8px 16px;
      font-size: 12px;
      border-color: #eaeaea;
      border-radius: 6px;
    }
    .noti-sending-text-input:hover {
      border-color: #523ee8 !important;
    }
  }
}

.table-vehicle_list-btn {
  color: #515151;
  width: 12px;
  height: 12px;
  position: absolute;
  top: -8px;
  left: -1px;

  :hover {
    color: #1f1f1f;
  }
}

#table-vehicle_info-dropdown {
  background-color: #f5f5f5;
  overflow-y: auto;
  scrollbar-color: transparent transparent;
  /* height: 300px; */
  min-height: auto;
  max-height: 300px;

  /* Hide the default scrollbar */
  scrollbar-width: none;
}

/* Make the scrollbar track transparent */
#table-vehicle_info-dropdown::-webkit-scrollbar {
  width: 8px; /* Adjust as needed */
}

#table-vehicle_info-dropdown::-webkit-scrollbar-track {
  background-color: #d9d9d9;
}

/* Style the scrollbar thumb if needed */
#table-vehicle_info-dropdown::-webkit-scrollbar-thumb {
  background-color: #b5b5b5; /* Adjust transparency as needed */
  border-radius: 5px;
}
.divider {
  border-top: 1px solid #eaeaea;
  margin-top: 4px;
  margin-bottom: -4px;
}
</style>
